import React from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useState } from "react";

export default function GenerateLink({ link, share, color, name }) {
  return (
    <GenerateLinkModal link={link} share={share} color={color} name={name} />
  );
}

function GenerateLinkModal({ link, share, color, name }) {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(link).then(() => {
      handleClose();
    });
  };

  return (
    <>
      <Button
        variant="contained"
        className={color == "blue" ? "blue-button" : "pink-button"}
        disabled={share}
        onClick={() => handleClick()}
      >
        {name}
      </Button>

      <Dialog
        BackdropProps={{ invisible: true }}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>votre lien vidéo</DialogTitle>
        <DialogContent>{link}</DialogContent>
        <DialogActions>
          <Button onClick={handleCopy}>Copier le lien </Button>

          <Button onClick={handleClose}>fermer</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
