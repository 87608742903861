import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { checkIsLoggedIn } from "./authSlice";
import { useSelector, useDispatch } from "react-redux";
import imgT from "../../Global/images/LogoLogin.png";
import {
    useGetPasswordResetValidateTokenQuery,
    useResetUserPasswordMutation,
} from "./authAPISlice";
import {
    showSuccess,
    showError,
} from "../../Global/notifications/notificationsSlice";
import CircularProgress from '@mui/material/CircularProgress';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

export default function PasswordResetPage(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { key } = useParams();
    const isLoggedin = useSelector(checkIsLoggedIn);

    const [password, setPassword] = React.useState("");
    const [confirmPassword, setConfirmPassword] = React.useState("");
    const [passwordsMatch, setPasswordsMatch] = React.useState(true);
    const [validationErrors, setValidationErrors] = React.useState({
        minLength: false,
        numericOnly: false,
        specialCharacter: false,
        uppercaseLetter: false,
        number: false,
    });
    const [passwordVisible, setPasswordVisible] = React.useState(false);
    const [passwordConfirmVisible, setPasswordConfirmVisible] = React.useState(false);
    const {
        isLoading: getPasswordResetValidateTokenLoading,
        isError: getPasswordResetValidateTokenError,
    } = useGetPasswordResetValidateTokenQuery(key);

    const [
        resetUserPassword,
        {
            data: resetUserPasswordData,
            isLoading: resetUserPasswordLoading,
            isSuccess: resetUserPasswordSuccess,
            isError: resetUserPasswordError,
            error: resetUserPasswordMessage,
        },
    ] = useResetUserPasswordMutation();

    useEffect(() => {
        if (resetUserPasswordSuccess) {
            dispatch(showSuccess(resetUserPasswordData?.success));
        } else if (resetUserPasswordError) {
            dispatch(showError(resetUserPasswordMessage?.data?.error));
        }
    }, [resetUserPasswordSuccess, resetUserPasswordError]);

    const handleSubmit = (event) => {
        event.preventDefault();
        if (
            validationErrors.minLength ||
            validationErrors.numericOnly ||
            validationErrors.specialCharacter ||
            validationErrors.uppercaseLetter ||
            validationErrors.number
        ) {
            dispatch(
                showError(
                    "Veuillez vous assurer que votre mot de passe répond aux exigences indiquées"
                )
            );
        } else {
            const body = {
                password: password,
            };
            const request = {
                body: body,
                key: key,
            };
            resetUserPassword(request)
                .unwrap()
                .then(() => {
                    navigate("/login");
                })
                .catch((error) => {});
        }
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
        setPasswordsMatch(event.target.value === confirmPassword);
        setValidationErrors({
            minLength: !hasMinimumLength(event.target.value),
            numericOnly: isNumericOnly(event.target.value),
            specialCharacter: !hasSpecialCharacter(event.target.value),
            uppercaseLetter: !hasUppercaseLetter(event.target.value),
            number: !hasNumber(event.target.value),
        });
    };

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
        setPasswordsMatch(event.target.value === password);
    };
    const hasSpecialCharacter = (password) => {
        return /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(password);
    };

    const hasUppercaseLetter = (password) => {
        return /[A-Z]/.test(password);
    };
    const hasNumber = (password) => {
        return /\d/.test(password);
    };
    const hasMinimumLength = (password) => {
        return password.length >= 8;
    };

    const isNumericOnly = (password) => {
        return /^\d+$/.test(password);
    };
    useEffect(() => {
        if (isLoggedin) {
            navigate("/tutorials");
        }
        if (getPasswordResetValidateTokenError) {
            dispatch(
                showError(
                    "Code de réinitialisation du mot de passe invalide ou expiré. Veuillez demander un nouveau lien de réinitialisation de mot de passe"
                )
            );
            navigate("/password-reset");
        }
    }, [isLoggedin, getPasswordResetValidateTokenError, navigate]);

    if (getPasswordResetValidateTokenLoading || resetUserPasswordLoading) {
        return   <Box sx={{ display: 'flex' }} display="flex"
  justifyContent="center"
  alignItems="center"
  minHeight="100vh">
      <CircularProgress />
    </Box>
    } else {
        return (
            <>
                <div className="center-wrapper">
                    <div className="center-login">
                        <Box component="form" onSubmit={handleSubmit}>
                            <img src={imgT} alt={"Logo"} />
                            <Grid display="flex" alignItems="center">
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                variant="standard"
                                value={password || ""}
                                onChange={handlePasswordChange}
                                name="password"
                                label="Entrez un nouveau mot de passe"
                                type={passwordVisible?"text":"password"}
                                id="password"
                                autoComplete="current-password"
                                error={
                                    validationErrors.minLength ||
                                    validationErrors.numericOnly ||
                                    validationErrors.specialCharacter ||
                                    validationErrors.uppercaseLetter ||
                                    validationErrors.number
                                }
                                helperText={
                                    (validationErrors.minLength &&
                                        "Le mot de passe doit comporter au moins 8 caractères.") ||
                                    (validationErrors.numericOnly &&
                                        "Le mot de passe ne peut pas être entièrement numérique.") ||
                                    (validationErrors.specialCharacter &&
                                        "Le mot de passe doit contenir au moins un caractère spécial.") ||
                                    (validationErrors.uppercaseLetter &&
                                        "Le mot de passe doit contenir au moins une lettre majuscule.") ||
                                    (validationErrors.number &&
                                        "Le mot de passe doit comporter au moins un chiffre.")
                                }
                            />
                            {passwordVisible?<VisibilityOffIcon onClick={()=>setPasswordVisible(false)} color="primary"/>:
                         <VisibilityIcon onClick={()=>setPasswordVisible(true)} color="primary"/>}
                            </Grid>
                            <Grid display="flex" alignItems="center">
                            <TextField
                                error={!passwordsMatch}
                                helperText={
                                    !passwordsMatch && "Les mots de passe ne correspondent pas"
                                }
                                margin="normal"
                                required
                                fullWidth
                                variant="standard"
                                value={confirmPassword || ""}
                                onChange={handleConfirmPasswordChange}
                                name="confirm-password"
                                label="Ré-entrez le nouveau mot de passe"
                                type={passwordConfirmVisible?"text":"password"}
                                id="confirm-password"
                                autoComplete="current-password"
                            />
                            {passwordConfirmVisible?<VisibilityOffIcon onClick={()=>setPasswordConfirmVisible(false)} color="primary"/>:
                         <VisibilityIcon onClick={()=>setPasswordConfirmVisible(true)} color="primary"/>}
                            </Grid>
                            <Button
                                style={{ marginTop: "5%" }}
                                disabled={!passwordsMatch}
                                type="submit"
                                fullWidth
                                className="pink-button"
                                variant="contained"
                            >
                               réinitialiser le mot de passe
                            </Button>
                        </Box>
                    </div>
                </div>
            </>
        );
    }
}
