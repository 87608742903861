import * as React from "react";
import Footer from "../../../Global/footer/Footer";
import { Grid } from "@mui/material";
import { setnavDrawer, setnavName } from "../videoSlice";
import { useDispatch } from "react-redux";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { useNavigate, useParams } from "react-router-dom";
import {
    useEditVideoMutation,
    useGetVideoQuery,
} from "../videoWorkflowAPISlice";
import EditVideoMenu from "./EditVideoMenu";
import {
    showError,
    showSuccess,
} from "../../../Global/notifications/notificationsSlice";
import CircularProgress from "@mui/material/CircularProgress";
import HowToFilm from "./HowToFilm";
export default function EditVideoSpecificProject() {
    const navigate = useNavigate();
    const { project_id, video_id, name } = useParams();
    const [video, setVideo] = React.useState(null);
    const [existingVideo, setExistingVideo] = React.useState(null);
    const [spaceName, setSpaceName] = React.useState("");
    const [spaceNameLength, setSpaceNameLength] = React.useState(0);
    const [description, setDescription] = React.useState("");
    const [descriptionLength, setDescriptionLength] = React.useState(0);
    const [descriptionNewLines, setDescriptionNewLines] = React.useState(0);
    const [videoDuration, setVideoDuration] = React.useState(null);

    const spaceNameAllowedLength = 30;
    const descriptionAllowedLengthPerLine = 30;
    const descriptionAllowedLines = 2;

    const {
        data: getVideo,
        isLoading: getVideoLoading,
        isSuccess: getVideoSuccess,
    } = useGetVideoQuery({ project_id, video_id });

    React.useEffect(() => {
        setExistingVideo(getVideo?.video);
        setSpaceName(getVideo?.name);
        setDescription(getVideo?.description);
        setVideoDuration(parseInt(getVideo?.length));
        setSpaceNameLength(getVideo?.name.length);
        setDescriptionLength(getVideo?.description.length-1);
        setDescriptionNewLines(getVideo?.description.split("\n").length);
    }, [getVideoSuccess, getVideo]);

    const [
        editVideo,
        {
            data: editVideoData,
            isLoading: editVideoLoading,
            isSuccess: editVideoSuccess,
            isError: editVideoError,
            error: editVideoMessage,
        },
    ] = useEditVideoMutation();

    React.useEffect(() => {
        if (editVideoSuccess) {
            dispatch(showSuccess(editVideoData?.success));
            navigate(`/project/${project_id}/${name}`);
        } else if (editVideoError) {
            dispatch(showError(editVideoMessage?.data.error));
        }
    }, [editVideoSuccess, editVideoError]);

    React.useEffect(() => {
        if (video && videoDuration) {
            handleSubmit();
        }
    }, [videoDuration, video]);

    const handleSubmit = async (e) => {
        const videoBlob = await fetch(video).then((response) =>
            response.blob()
        );

        const formData = new FormData();

        if (videoBlob.type !== "text/html") {
            formData.append("video", videoBlob, "video.mp4");
            formData.append("length", videoDuration);
        }
        formData.append("project", project_id);
        formData.append("id", video_id);
        formData.append("name", spaceName);
        formData.append("description", description);

        const request = {
            body: formData,
            project_id: project_id,
            video_id: video_id,
        };
        editVideo(request);
    };

    const handleVideoChange = (newVideoUrl) => {
        setVideo(newVideoUrl);
        setExistingVideo(null);
    };
    React.useEffect(() => {
        return () => {
            if (video) {
                URL.revokeObjectURL(video);
            }
        };
    }, [video]);

    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(setnavName("Nouveau projet"));
        dispatch(setnavDrawer(false));
    }, []);

    const handleNameChange = (e) => {
      setSpaceName(e.target.value);
      setSpaceNameLength(e.target.value.length);
  };
  const handleDescriptionChange = (e) => {
      let inputValue = e.target.value;
      let lines = inputValue.split("\n");

      lines = lines.map((line) => {
          if (line.length > descriptionAllowedLengthPerLine) {

              let chunks = [];
              while (line.length > 0) {
                  chunks.push(
                      line.substring(0, descriptionAllowedLengthPerLine)
                  );
                  line = line.substring(descriptionAllowedLengthPerLine);
              }
              return chunks.join("\n");
          }
          return line;
      });


      lines = lines.slice(0, descriptionAllowedLines);
      setDescriptionLength(e.target.value.length);
      setDescriptionNewLines(lines.length);
      setDescription(lines.join("\n"));
  };

    if (editVideoLoading || getVideoLoading) {
        return (
            <Box
                sx={{ display: "flex" }}
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
            >
                <CircularProgress />
            </Box>
        );
    } else {
        return (
            <>
                <div className="center-wrapper">
                    <Box component="form" onSubmit={handleSubmit}>
                        <div className="center-form-elements">
                            <p style={{ color: "#6E7070" }}>
                                Nommez votre espace et ajoutez une description,
                                celle-ci apparaîtra sur la vidéo finale
                            </p>
                            <TextField
                                margin="normal"
                                variant="standard"
                                required
                                fullWidth
                                value={spaceName || ""}
                                onChange={(e) => handleNameChange(e)}
                                placeholder="Salon, chambre, cuisine, salle de bain…"
                                id="name"
                                label="Nom de la piece"
                                name="name"
                                autoComplete="name"
                                inputProps={{
                                  maxLength: spaceNameAllowedLength,
                              }}
                              helperText={`
                              Caractères restants ${
                                  spaceNameAllowedLength - spaceNameLength
                              }`}
                            />
                            <TextField
                                multiline
                                maxRows={2}
                                margin="normal"
                                variant="standard"
                                required
                                fullWidth
                                value={description || ""}
                                onChange={(e) => handleDescriptionChange(e)}
                                id="description"
                                placeholder="Grand salon, 60 m2, table pour 8 personnes, spacieux et lumineux."
                                label="Description"
                                name="description"
                                autoComplete="description"
                                inputProps={{
                                  maxLength:
                                      descriptionAllowedLengthPerLine *
                                          descriptionAllowedLines +
                                      1,
                              }}
                              helperText={`Nouvelles lignes restantes ${
                                  descriptionAllowedLines -
                                  descriptionNewLines
                              },
                              caractères restants ${
                                  (descriptionAllowedLengthPerLine *
                                      descriptionAllowedLines)+1 -
                                  descriptionLength
                              },
                              `}
                            />

                            <Grid item xs={12}>
                                <HowToFilm />
                            </Grid>
                            <Grid item xs={12} sx={{ mb: 35 }}>
                                {videoDuration !== null && (
                                    <p>
                                        Durée de la vidéo:{" "}
                                        {videoDuration?.toFixed(2)} seconds
                                    </p>
                                )}
                            </Grid>
                        </div>

                        {/* <div className="video-continer">
                            <div className="player-wrapper">
                                {video && <video src={video} controls />}
                                {existingVideo && <video src={existingVideo} controls />}
                            </div>
                        </div> */}
                    </Box>
                </div>
                <EditVideoMenu
                    setVideoDuration={setVideoDuration}
                    handleSubmit={handleSubmit}
                    handleVideoChange={handleVideoChange}
                    spaceName={spaceName}
                    description={description}
                />
            </>
        );
    }
}
