import { memo } from "react";
import { useDrag, useDrop } from "react-dnd";
import { ItemTypes } from "./ItemTypes.js";
const style = {
  padding: "0.5rem 1rem",
  marginBottom: ".5rem",
  backgroundColor: "white",
  cursor: "move",
  alignItems: "center",
  justifyContext: "center",
  borderBottom: '1px solid gray',
};
export const Card = memo(function Card({
  order,
  text,
  moveCard,
  findCard,
  icon,
  length,
}) {
  const originalIndex = findCard(order).index;
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: ItemTypes.CARD,
      item: { order, originalIndex },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const { order: droppedId, originalIndex } = item;
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          moveCard(droppedId, originalIndex);
        }
      },
    }),
    [order, originalIndex, moveCard]
  );
  const [, drop] = useDrop(
    () => ({
      accept: ItemTypes.CARD,
      hover({ order: draggedId }) {
        if (draggedId !== order) {
          const { index: overIndex } = findCard(order);
          moveCard(draggedId, overIndex);
        }
      },
    }),
    [findCard, moveCard]
  );
  const opacity = isDragging ? 0.5 : 1;
  return (
    <div ref={(node) => drag(drop(node))} style={{ ...style, opacity }}>
      {icon}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      {text} &nbsp;
      &nbsp;&nbsp;&nbsp;&nbsp;
      Position &nbsp; {order}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </div>
  );
});
