import * as React from "react";
import Footer from "../../Global/footer/Footer";
import { Button, Grid, Typography } from "@mui/material";
import { setnavDrawer, setnavName } from "./videoSlice";
import { useDispatch } from "react-redux";
import theme from "../../Global/theme";
import Card from "@mui/material/Card";
import { useGetProjectsQuery } from "./videoWorkflowAPISlice";
import DrawerBottom from "./Project/DrawerBottom";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import HowToFilm from "./Video/HowToFilm";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

export default function LandingPage() {
  const navigate = useNavigate();
  const [finalVideo, setFinalVideo] = React.useState(false);

  const handleClick = () => {
    navigate("/project");
  };
  const gotoFinalVideo = (id, name) => {
    navigate(`/project/${id}/${name}/final-video/`);
  };
  const goToProject = (id, name) => {
    navigate(`/project/${id}/${name}/`);
  };
  const dispatch = useDispatch();
  dispatch(setnavName("Mes projets"));
  dispatch(setnavDrawer(true));

  const {
    data: projects_list,
    isLoading: projectsLoading,
    isSuccess: projectVideoListSuccess,
    isError: projectsError,
  } = useGetProjectsQuery();

  if (projectsLoading || projectsError) {
    return (
      <Box
        sx={{ display: "flex" }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  } else if (projects_list < 1) {
    return (
      <>
        <h5 style={{ textAlign: "center", mt: 50 }}>
          Démarrez votre premier projet
        </h5>
        <HowToFilm />
        <Grid container justifyContent="right" style={{ marginTop: "50px" }}>
          <Button
            onClick={handleClick}
            className="pink-button rounded-button"
            variant="contained"
            endIcon={<AddIcon />}
          >
            nouveau projet
          </Button>
        </Grid>

        <Footer />
      </>
    );
  } else {
    return (
      <>
        <Grid
          container
          columns={{ xs: 12, sm: 12, md: 12 }}
          gap={2}
          sx={{ mt: 10, mb: 15, p: 2 }}
          direction="row"
          justifyContent="left"
          alignItems="center"
        >
          {projects_list?.map((project) => (
            <Grid item key={project.id} align="center">
              <Card sx={theme.landingPageCardStyle} elevation={10}>
                <Grid item align="right" xs={12}>
                  <DrawerBottom
                    id={project.id}
                    name={project.name}
                    link={project.project_final_video.public_link}
                    share={project.project_final_video.video ? false : true}
                  />
                </Grid>
                <Grid
                  item
                  align="center"
                  justifyContent="center"
                  sx={{ mt: 2 }}
                  xs={12}
                >
                  {project.project_final_video.video ? (
                    <PlayCircleOutlineIcon
                      onClick={() => {
                        gotoFinalVideo(project.id, project.name);
                      }}
                      style={{ color: "grey" }}
                    />
                  ) : (
                    <>
                      <EditIcon
                        onClick={() => {
                          goToProject(project.id, project.name);
                        }}
                        style={{ color: "#E50F81" }}
                      />
                    </>
                  )}
                </Grid>
              </Card>
              <Typography
                style={{
                  color: "#000",
                  margin: "auto",
                }}
              >
                {project.name}
              </Typography>
            </Grid>
          ))}
          <Grid container justifyContent="right" style={{ marginTop: "50px" }} sx={{mb:5}}>
            <Button
              onClick={handleClick}
              className="pink-button rounded-button"
              variant="contained"
              style={theme.fabStyle}
              endIcon={<AddIcon />}
            >
              nouveau projet
            </Button>
          </Grid>
        </Grid>

        <Footer />
      </>
    );
  }
}
