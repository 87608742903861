import React from "react";
import { getIsSuccess, getSuccessMessage, hideSuccess, 
  getIsError, getErrorMessage, hideError } from "./notificationsSlice";

import { useSelector, useDispatch } from "react-redux";

import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import MuiAlert from "@mui/material/Alert";
import Slide from "@mui/material/Slide";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Notifications() {

  const dispatch = useDispatch();
  const isSuccess = useSelector(getIsSuccess);
  const successMessage = useSelector(getSuccessMessage)
  const isError = useSelector(getIsError);
  const errorMessage = useSelector(getErrorMessage)

  function handleSuccessClose() {
    dispatch(hideSuccess());
  }
  
  function handleErrorClose() {
    dispatch(hideError());
  }

  return (
    <>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar open={isSuccess} anchorOrigin={{ vertical: "top", horizontal: "left" }} TransitionComponent={Transition} onClose={handleSuccessClose}>
          <Alert onClose={handleSuccessClose} severity="success" sx={{ width: "100%" }}>
            {successMessage}
          </Alert>
        </Snackbar>
        <Snackbar open={isError} anchorOrigin={{ vertical: "top", horizontal: "left" }} TransitionComponent={Transition} onClose={handleErrorClose}>
          <Alert onClose={handleErrorClose} severity="error" sx={{ width: "100%" }}>
            {errorMessage}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
};