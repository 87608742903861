import * as React from "react";
import Footer from "../../../Global/footer/Footer";
import { Grid } from "@mui/material";
import { setnavDrawer, setnavName } from "../videoSlice";
import { useDispatch } from "react-redux";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { useNavigate, useParams } from "react-router-dom";
import { useUploadVideoMutation } from "../videoWorkflowAPISlice";
import TakeVideoMenu from "./TakeVideoMenu";
import {
    showError,
    showSuccess,
} from "../../../Global/notifications/notificationsSlice";
import HowToFilm from "./HowToFilm";

export default function TakeVideoSpecificProject() {
    const navigate = useNavigate();
    const { project_id, name } = useParams();
    const [video, setVideo] = React.useState(null);
    const [spaceName, setSpaceName] = React.useState("");
    const [spaceNameLength, setSpaceNameLength] = React.useState(0);
    const [description, setDescription] = React.useState("");
    const [descriptionLength, setDescriptionLength] = React.useState(0);
    const [descriptionNewLines, setDescriptionNewLines] = React.useState(0);
    const [videoDuration, setVideoDuration] = React.useState(null);

    const spaceNameAllowedLength = 30;
    const descriptionAllowedLengthPerLine = 30;
    const descriptionAllowedLines = 2;

    const [
        uploadVideo,
        {
            data: uploadVideoData,
            isLoading: uploadVideoLoading,
            isSuccess: uploadVideoSuccess,
            isError: uploadVideoError,
            error: uploadVideoMessage,
        },
    ] = useUploadVideoMutation();

    React.useEffect(() => {
        if (uploadVideoSuccess) {
            dispatch(showSuccess(uploadVideoData?.success));
            navigate(`/project/${project_id}/${name}`);
        } else if (uploadVideoError) {
            dispatch(showError(uploadVideoMessage?.data.error));
        }
    }, [uploadVideoSuccess, uploadVideoError]);

    React.useEffect(() => {
        if (video && videoDuration) {
            handleSubmit();
        }
    }, [videoDuration, video]);

    const handleSubmit = async (e) => {
        const videoBlob = await fetch(video).then((response) =>
            response.blob()
        );

        const formData = new FormData();

        formData.append("video", videoBlob, "video.mp4");

        formData.append("project", project_id);
        formData.append("name", spaceName);
        formData.append("description", description);
        formData.append("length", videoDuration);
        const request = {
            body: formData,
            project_id: project_id,
        };
        uploadVideo(request);
    };

    const handleVideoChange = (newVideoUrl) => {
        setVideo(newVideoUrl);
    };
    React.useEffect(() => {
        return () => {
            if (video) {
                URL.revokeObjectURL(video);
            }
        };
    }, [video]);

    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(setnavName("Nouveau projet"));
        dispatch(setnavDrawer(false));
    }, []);

    const handleNameChange = (e) => {
        setSpaceName(e.target.value);
        setSpaceNameLength(e.target.value.length);
    };
    const handleDescriptionChange = (e) => {
        let inputValue = e.target.value;
        let lines = inputValue.split("\n");

        lines = lines.map((line, index) => {
          if (line.length > descriptionAllowedLengthPerLine && index !== 0) {
            let chunks = [];
            while (line.length > 0) {
              chunks.push(line.substring(0, descriptionAllowedLengthPerLine));
              line = line.substring(descriptionAllowedLengthPerLine);
            }
            return chunks.join("\n");
          } else if (line.length > descriptionAllowedLengthPerLine) {
            let chunks = [line.substring(0, descriptionAllowedLengthPerLine)];
            line = line.substring(descriptionAllowedLengthPerLine);
            return chunks.join("\n") + (line.length > 0 ? `\n${line}` : '');
          }
          return line;
        });

        // Remove empty lines at the end if the maximum line count is exceeded
        while (lines.length > descriptionAllowedLines) {
          lines.pop();
        }

        setDescriptionLength(e.target.value.length);
        setDescriptionNewLines(lines.length);
        setDescription(lines.join("\n"));
    };

    if (uploadVideoLoading) {
        return (
            <Box
                sx={{ display: "flex" }}
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
            >
                <CircularProgress />
            </Box>
        );
    } else {
        return (
            <>
                <div className="center-wrapper">
                    <Box component="form" onSubmit={handleSubmit}>
                        <div className="center-form-elements">
                            <p style={{ color: "#6E7070" }}>
                                Nommez votre espace et ajoutez une description,
                                celle-ci apparaîtra sur la vidéo finale. La
                                vidéo finale ne doit pas durer plus de 60
                                secondes
                            </p>
                            <TextField
                                margin="normal"
                                variant="standard"
                                required
                                fullWidth
                                value={spaceName || ""}
                                onChange={(e) => handleNameChange(e)}
                                placeholder="Salon, chambre, cuisine, salle de bain…"
                                id="name"
                                label="Nom de la piece"
                                name="name"
                                autoComplete="name"
                                inputProps={{
                                    maxLength: spaceNameAllowedLength,
                                }}
                                helperText={`
                                Caractères restants ${
                                    spaceNameAllowedLength - spaceNameLength
                                }`}
                            />
                            <TextField
                                multiline
                                maxRows={2}
                                margin="normal"
                                variant="standard"
                                required
                                fullWidth
                                value={description || ""}
                                onChange={(e) => handleDescriptionChange(e)}
                                placeholder="Grand salon, 60 m2, table pour 8 personnes, spacieux et lumineux."
                                id="description"
                                label="Description"
                                name="description"
                                autoComplete="description"
                                inputProps={{
                                    maxLength:
                                        descriptionAllowedLengthPerLine *
                                            descriptionAllowedLines +
                                        1,
                                }}
                                helperText={`Nouvelles lignes restantes ${
                                    descriptionAllowedLines -
                                    descriptionNewLines
                                },
                                caractères restants ${
                                    (descriptionAllowedLengthPerLine *
                                        descriptionAllowedLines)+1 -
                                    descriptionLength
                                },
                                `}
                            />

                            <Grid item xs={12} sx={{ mt: 5, mb: 25 }}>
                                <HowToFilm />
                            </Grid>
                        </div>
                    </Box>
                </div>
                <TakeVideoMenu
                    setVideoDuration={setVideoDuration}
                    handleSubmit={handleSubmit}
                    handleVideoChange={handleVideoChange}
                    spaceName={spaceName}
                    description={description}
                />
            </>
        );
    }
}
