import React from "react";
import { Routes, Route } from "react-router-dom";
import LoginPage from "../../Features/Authentication/LoginPage";
import PrivateRoute from "./PrivateRoute";
import Notifications from "../../Global/notifications/Notifications";
import { checkIsLoggedIn } from "../../Features/Authentication/authSlice";
import { useSelector } from "react-redux";
import LandingPage from "../../Features/VideoWorkflow/LandingPage";
import RegisterPage from "../../Features/Authentication/RegisterPage";
import PasswordResetPage from "../../Features/Authentication/PasswordResetPage";
import RequestPasswordResetPage from "../../Features/Authentication/RequestPasswordResetPage";
import PasswordSetPage from "../../Features/Authentication/PasswordSetPage";
import TutorialsPage from "../../Features/Tutorials/TutorialsPage";
import Navbar from "../navbar/Navbar";
import NewProject from "../../Features/VideoWorkflow/Project/NewProject";
import SpecificProject from "../../Features/VideoWorkflow/Project/SpecificProject";
import TakeVideoSpecificProject from "../../Features/VideoWorkflow/Video/TakeVideoSpecificProject";
import FAQPage from "../../Features/FAQ/FAQPage";
import DragAndReallignVideos from "../../Features/VideoWorkflow/Video/DragAndDrop/DragAndRealignVideos";
import ViewPublicFinal from "../../Features/VideoWorkflow/ViewPublicFinal";
import EditProject from "../../Features/VideoWorkflow/Project/EditProject";
import EditProjectAudio from "../../Features/VideoWorkflow/Project/EditProjectAudio";
import EditVideoSpecificProject from "../../Features/VideoWorkflow/Video/EditVideoSpecificProject";
import FinalVideoPage from "../../Features/FinalVideo/FinalVideoPage";

export default function MainRouter() {
    const isLoggedIn = useSelector(checkIsLoggedIn);

    return (
        <>
            {isLoggedIn ? <>
            <Navbar/>
            </> : <></>}

            <Routes>
                {/* Private Routes */}
                <Route element={<PrivateRoute />}>
                    <Route exact path="/" element={<LandingPage />} />
                    <Route exact path="/tutorials" element={<TutorialsPage />} />
                    <Route exact path="/faq" element={<FAQPage />} />
                    <Route exact path="/project" element={<NewProject />} />
                    <Route exact path="/project/edit/:project_id" element={<EditProject />} />
                    <Route exact path="/project/:project_id/:name/" element={<SpecificProject />} />
                    <Route exact path="/project/:project_id/:name/final-video/" element={<FinalVideoPage />} />
                    <Route exact path="/project/:project_id/video-reorder/:name" element={<DragAndReallignVideos />} />
                    <Route exact path="/project/:project_id/video/:name" element={<TakeVideoSpecificProject />} />
                    <Route exact path="/project/:project_id/edit-audio/:name" element={<EditProjectAudio />} />
                    <Route exact path="/project/:project_id/edit-video/:video_id/:name" element={<EditVideoSpecificProject />} />
                </Route>


                {/* Public Routes */}
                <Route exact path="/login" element={<LoginPage />}></Route>
                <Route exact path="/register" element={<RegisterPage />}></Route>
                <Route exact path="/password-reset" element={<RequestPasswordResetPage />}></Route>
                <Route exact path=":key/password-reset" element={<PasswordResetPage />}></Route>
                <Route exact path=":key/password-set" element={<PasswordSetPage />}></Route>
                <Route exact path=":pub_key/view-pub-final" element={<ViewPublicFinal />}></Route>
            </Routes>

            <Notifications />
        </>
    );
}
