import { createSlice, createAsyncThunk } from "@reduxjs/toolkit/";
import { clearCookie, getCookie, setCookie, parseJwt } from "../api/apiHelper";

const LOGIN_URL = `${process.env.REACT_APP_SECRET_API}/auth/login/`;

export const loginUser = createAsyncThunk("login/loginUser", async (body) => {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },

        body: JSON.stringify(body),
    };
    const response = await fetch(LOGIN_URL, requestOptions);

    return response.json();
});

const initialState = getCookie("access")
    ? {
          token: `Bearer ${getCookie("access")}`,
          isLoggedIn: true,
          isLoginError: false,
          account_profile_abbreviation: `${parseJwt(getCookie("access")).account_profile_abbreviation}`,
          email: `${parseJwt(getCookie("access")).email}`,
          username: `${parseJwt(getCookie("access")).user}`,
          contact_number: `${parseJwt(getCookie("access")).contact_number}`,
      }
    : {
          token: "",
          isLoggedIn: false,
          isLoginError: false,
          account_profile_abbreviation: "",
          email: "",
          username:"",
          contact_number: "",
      };

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        logout: (state) => {
            state.isLoggedIn = false;
            state.token = "";
            clearCookie();
        },
    },
    extraReducers(builder) {
        builder.addCase(loginUser.fulfilled, (state, action) => {
            let response = action.payload;
            if (response.access) {
                state.token = response.access;
                state.isLoggedIn = true;
                setCookie(response);
                state.isLoginError = false;
                state.account_profile_abbreviation= `${parseJwt(response.access).account_profile_abbreviation}`
                state.username = `${parseJwt(response.access).user}`
                state.email= `${parseJwt(response.access).email}`
                state.contact_number= `${parseJwt(response.access).contact_number}`
                state.username =  `${parseJwt(response.access).user}`
            } else {
                state.isLoginError = true;
            }
        });
    },
});

export const getToken = (state) => state.auth.token;
export const checkIsLoggedIn = (state) => state.auth.isLoggedIn;
export const checkIsLoginError = (state) => state.auth.isLoginError;
export const getUserEmail = (state) => state.auth.email;
export const getUsername = (state) => state.auth.username;
export const getUserContactNumber = (state) => state.auth.contact_number;
export const getUserProfileAbbreviation = (state) => state.auth.account_profile_abbreviation;

export const { logout } = authSlice.actions;

export default authSlice.reducer;
