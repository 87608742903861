import * as React from "react";
import Footer from "../../Global/footer/Footer";
import { Grid } from "@mui/material";
import { setnavDrawer, setnavName } from "../VideoWorkflow/videoSlice";
import { useDispatch } from "react-redux";
import { useGetFAQQuery } from "../api/apiSlice";
export default function FAQPage() {
  const dispatch = useDispatch();
  dispatch(setnavName("Foire Aux Questions"));
  dispatch(setnavDrawer(true))
  const {
    data: faq_list,
    isLoading: faqLoading,
    isSuccess: faqSuccess,
    isError: faqError,
    error: faqMessage,
  } = useGetFAQQuery();

  if (faqLoading) {
    return <></>;
  } else if (faqError) {
    return <></>;
  } else {
    return (
      <>
        <Grid
          container
          gap={2}
          sx={{ p: 4 }}
          columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          {faq_list.map((faq) => (
            <>
              <Grid item align="left" xs={12} sx={{ mt: -5 }}>
                <h4> Q: {faq.question} </h4>
              </Grid>
              <Grid item align="left" xs={12} sx={{ mt: -5 }}>
                <h6> A: {faq.answer} </h6>
              </Grid>
            </>
          ))}
        </Grid>
        <Footer />
      </>
    );
  }
}
