import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';
import MenuIcon from '@mui/icons-material/Menu';

export const Nav = styled.nav`
  background:  #86CDD9;
  height: 80px;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;
  position: fixed;
   flexDirection: column;
  top: 0;
  z-index: 10;


`;

export const NavLink = styled(Link)`
  color: #fff;
  display: flex;
  align-items: left;
  text-decoration: none;
  height: 100%;

  cursor: pointer;
  &.active {
    color: #4f4f4f;
  }
 `;

export const ImgLink = styled(Link)`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  position: absolute;
  right: 3%;
  cursor: pointer;
  &.active {
    color: #15cdfc;
  }


    `;

  export const DivLink = styled.div`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  position: absolute;
  left: 3%;
  cursor: pointer;
  &.active {
    color: #15cdfc;
  }
  @media screen and (max-width: 600px) {
    left: 0;
    }

    `;

    export const ProfileLink = styled.div`
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    position: absolute;
    right: 3%;
    margin-top: 20px;
    cursor: pointer;
    &.active {
      color: #15cdfc;
    }
    @media screen and (max-width: 600px) {
      right: 0;
      }

      `;

export const Bars = styled(MenuIcon)`
@media screen and (max-width: 320px) {
  display: block;
  position: absolute;
  left: 0;
  justify-content: left;
  cursor: pointer;
  justify-content: flex-start;
}

@media screen and (max-width: 600px) {
  justify-content: left;
  position: absolute;
  left:0;
}
`;
export const NavText = styled.div`
  justify-content: center;
  align-items: center;
  position: absolute;
  text-align: center;
  right:46%;

  @media screen and (max-width: 600px) {
  justify-content: center;
  align-items: center;
  position: absolute;
  text-align: center;
  left: 8%;
  }
`;
export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-left: 220px;
  margin-right: 220px;

  white-space: nowrap;
  @media screen and (max-width: 1100px) {
    display: flex;
    align-items: center;
    margin-left: 0px;
    margin-right: 0px;
  }
  @media screen and (max-width: 600px) {
    display: none;
    margin-left: 0px;
    margin-right: 0px;
  }
`;

export const NavBtn = styled.nav`
  align-items: center;
  display: flex;

  margin-right: 15px;
  @media screen and (max-width: 1200px) {
    display: none;
  }
`;

