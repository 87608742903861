import styled from 'styled-components';

// flex smaller 
export const Nav = styled.nav`
background: #fff;
bottom: 0;
width: 100%;
justify-content: center;
margin-top: 100px;
alignItems: center;

  display: grid; 
  mind-width: fit-content;

`;

export const FooterLink = styled.img`
color: #3F8CC4;
margin-bottom: 0px;
margin-left: auto;
margin-right: auto;
text-decoration: none;
justify-content: center;
alignItems: center;

&:hover {
	color: #4F4F4F;
	transition: 200ms ease-in;
}
`;
export const FooterName = styled.p`
color: #4F4F4F;
margin-bottom: 10px;
text-decoration: none;
justify-content: center;
alignItems: center;

`;


