import * as React from "react";
import Footer from "../../Global/footer/Footer";
import { Grid } from "@mui/material";
import { setnavName } from "../VideoWorkflow/videoSlice";
import { useDispatch } from "react-redux";
import DrawerBottom from "./FinalVideoMenu";
import { useParams } from "react-router-dom";
import { useGetFinalVideoQuery } from "../VideoWorkflow/videoWorkflowAPISlice";
import PlayFinalVideo from "./PlayFinalVideo";
import Card from "@mui/material/Card";
import theme from "../../Global/theme";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
export default function FinalVideoPage() {
  const { project_id } = useParams();
  const { name } = useParams();

  const { data: finalVideo, isLoading: finalVideoLoading } =
    useGetFinalVideoQuery(project_id);

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(setnavName(name));
  }, []);

  if (finalVideoLoading) {
    return   <Box sx={{ display: 'flex' }} display="flex"
  justifyContent="center"
  alignItems="center"
  minHeight="100vh">
      <CircularProgress />
    </Box>
  } else {
    return (
      <>
        <Grid
          container
          sx={{ mt: 10, p: 2 }}
          columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
          direction="row"
          justifyContent="left"
          alignItems="left"
        >
          <Grid item xs={2} md={4}></Grid>
          <Grid item xs={8}>
            <Card style={theme.finalVideoCardStyle}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{ mt: 8 }}
              >
                <PlayFinalVideo video={finalVideo} />{" "}
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={2} md={4} />
          <Grid item xs={2} />
          <Grid item md={4} />
          <Grid item xs={8} md={4}>
            <h4>{name}</h4>
          </Grid>
          <Grid item xs={2} md={4} />
        </Grid>

        <DrawerBottom project_id={project_id} video={finalVideo} />
        {/* <Footer /> */}
      </>
    );
  }
}
