import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getToken } from "./apiHelper";

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_SECRET_API}`,
    prepareHeaders: (headers) => {
      const token = getToken();
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),

  tagTypes: ["selected_audio","final_public_video","final_video", "register_user", "password_reset", "audio_list", "tutorial_videos", "faq", "projects","create_project", "project_video_list","video","project"],
  endpoints: (builder) => ({

    getTutorialVideos: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_SECRET_API}/video/tutorials/`,

      }),
      providesTags: ["tutorial_videos"],
    }),

    getFAQ: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_SECRET_API}/video/faq/`,

      }),
      providesTags: ["faq"],
    }),



}),
});

export const {
  useGetTutorialVideosQuery,
  useGetFAQQuery,

} = apiSlice;