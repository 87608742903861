import { createSlice } from "@reduxjs/toolkit/";

const initialState = {
  navName:"Mes projets",
  navDrawer: true,
  VideoModalOpen: false,
  videoProgress: 0,
  videoProgressWarning: "",

};

export const videoSlice = createSlice({
  name: "video",
  initialState,

  reducers: {
    setnavName:(state, action) =>{
      state.navName = action.payload;
    },
    setnavDrawer:(state, action) =>{
      state.navDrawer = action.payload;
    },
    setVideoModalOpen:(state, action) =>{
      state.VideoModalOpen = action.payload;
    },
    setVideoProgress:(state, action) =>{
      state.videoProgress = action.payload;
    },
    setVideoProgressWarning:(state, action) =>{
      state.videoProgressWarning = action.payload;
    }
  },
});

export const getnavName = (state)=> state.video.navName;
export const getnavDrawer = (state)=> state.video.navDrawer;
export const getVideoModalOpen = (state)=> state.video.VideoModalOpen;
export const getVideoProgress = (state)=> state.video.videoProgress;
export const getVideoProgressWarning = (state)=> state.video.videoProgressWarning;

export const { setnavName,setVideoModalOpen, setVideoProgress, setVideoProgressWarning, setnavDrawer} = videoSlice.actions;

export default videoSlice.reducer;
