import React from "react";
import Button from "@mui/material/Button";
import { Grid } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";

export default function EditVideoMenu({
    setVideoDuration,
    handleSubmit,
    handleVideoChange,
    spaceName,
    description
}) {


    const handleCapture = (e) => {
        if (e.target.files) {
            if (e.target.files.length !== 0) {
                const file = e.target.files[0];
                const newUrl = URL.createObjectURL(file);

                const videoElement = document.createElement("video");
                videoElement.src = newUrl;

                videoElement.addEventListener("loadedmetadata", () => {

                    const durationInSeconds = videoElement.duration;

                    setVideoDuration(durationInSeconds);
                    handleVideoChange(newUrl);

                });

                videoElement.load();
            }
        }
    };


    return <DrawerBottom handleCapture={handleCapture} handleSubmit={handleSubmit} spaceName={spaceName} description={description}/>;
}

const drawerBleeding = 56;

const Root = styled("div")(({ theme }) => ({
    width: "100%",
    justifyContent: "center",
    marginTop: " 70px",
    bottom: 0,
    position: "fixed",
  }));

  const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "light" ? "#FBFEFF" : grey[800],
    boxShadow: "12px 12px 12px 12px grey",
  }));

  const Puller = styled(Box)(({ theme }) => ({
    width: 30,
    height: 6,
    backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
    borderRadius: 3,
    position: "absolute",
    top: 8,
    left: "calc(50% - 15px)",
  }));

function DrawerBottom({ handleSubmit, handleCapture, spaceName, description }) {


    return (
        <Root>
            <Global
                styles={{
                    ".MuiDrawer-root > .MuiPaper-root": {
                        overflow: "visible",
                    },
                }}
            />



                <StyledBox
                    sx={{
                        px: 2,
                        pb: 2,
                        height: "100%",
                        marginTop: "10px",
                        overflow: "auto",
                    }}
                >
                    <ButtonsWithinDrawer
                        handleSubmit={handleSubmit}
                        handleCapture={handleCapture}
                        spaceName={spaceName}
                        description={description}
                    />
                </StyledBox>

        </Root>
    );
}

function ButtonsWithinDrawer({spaceName, description, handleSubmit, handleCapture }) {
    const { project_id, name } = useParams();
    const navigate = useNavigate();
    const handleCancel = () => {
        navigate(`/project/${project_id}/${name}`);
    };
    const isCaptureVideoButtonDisabled = () => {
        return (
            spaceName==="" ||
            description ===""
        );
    };
    return (
        <Grid
            container
            sx={{ p: 4 }}
            gap={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            <Grid item align="center" xs={12}>
                <input
                    name="video"
                    className="cam-input"
                    accept="video/*"
                    id="icon-button-file"
                    type="file"
                    capture="environment"
                    onChange={(e) => handleCapture(e)}
                />
                <label htmlFor="icon-button-file">
                    <Button
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                        variant="contained"
                        className="blue-button"
                        onClick={handleCapture}
                        disabled={isCaptureVideoButtonDisabled()}
                    >
                        filmer une nouvelle video
                    </Button>
                </label>
            </Grid>
            <Grid item align="center" xs={12}>
                <input
                    name="video"
                    className="cam-input"
                    accept="video/*"
                    id="icon-button-file-local"
                    type="file"
                    onChange={(e) => handleCapture(e)}
                />
                <label htmlFor="icon-button-file-local">
                    <Button
                        color="primary"
                        aria-label="upload picture local"
                        component="span"
                        variant="contained"
                        className="blue-button"
                        onClick={handleCapture}
                        disabled={isCaptureVideoButtonDisabled()}
                    >
                        Télécharger une nouvelle piece
                    </Button>
                </label>
            </Grid>
            <Grid item align="center" xs={12}>
                <Button
                    variant="contained"
                    className="pink-button"
                    onClick={handleSubmit}
                    disabled={isCaptureVideoButtonDisabled()}

                >
                    valider
                </Button>
            </Grid>
            <Grid item align="center" xs={12}>
                <Button
                    variant="contained"
                    className="yellow-button"
                    onClick={handleCancel}
                >
                    Annuler
                </Button>
            </Grid>
        </Grid>
    );
}
