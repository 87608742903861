import React from "react";
import Box from "@mui/material/Box";
import EditAudioSelect from "./EditAudioSelect";
import {
  useGetAudioListQuery,
  useGetProjectAudioQuery,
  useUpdateProjectAudioMutation,
} from "../videoWorkflowAPISlice";
import { setnavDrawer, setnavName } from "../videoSlice";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  showError,
  showSuccess,
} from "../../../Global/notifications/notificationsSlice";
import CircularProgress from "@mui/material/CircularProgress";
export default function EditProjectAudio({}) {
  const { project_id, name } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedAudio, setSelectAudio] = React.useState(1);

  React.useEffect(() => {
    dispatch(setnavName("Sélectionnez le son"));
    dispatch(setnavDrawer(false));
  }, []);

  const { data: audioList, isLoading: getAudioListLoading } =
    useGetAudioListQuery();

  const { data: projectAudio, isLoading: getProjectAudioLoading } =
    useGetProjectAudioQuery(project_id);

  const [
    updateProjectAudio,
    {
      data: updateProjectData,
      isLoading: updateProjectLoading,
      isSuccess: updateProjectSuccess,
      isError: updateProjectError,
      error: updateProjectMessage,
    },
  ] = useUpdateProjectAudioMutation();

  React.useEffect(() => {
    if (updateProjectSuccess) {
      dispatch(showSuccess(updateProjectData?.success));
      navigate(`/project/${project_id}/${name}`);
    } else if (updateProjectError) {
      dispatch(showError(updateProjectMessage?.data.error));
    }
  }, [updateProjectSuccess, updateProjectError]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const body = {
      audio: selectedAudio,
    };
    const request = { body: body, project_id: project_id };
    updateProjectAudio(request);
  };

  if (getAudioListLoading || getProjectAudioLoading || updateProjectLoading) {
    return (
      <Box
        sx={{ display: "flex" }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  } else {
    return (
      <div className="center-wrapper">
        <Box component="form" onSubmit={handleSubmit}>
          <div className="center-form-elements"></div>
        </Box>
        <EditAudioSelect
          trackList={audioList}
          setSelectAudio={setSelectAudio}
          projectAudio={projectAudio}
          handleSubmit={handleSubmit}
        />
      </div>
    );
  }
}
