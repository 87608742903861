import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import { checkIsLoggedIn, checkIsLoginError, loginUser } from "./authSlice";
import { useSelector, useDispatch } from "react-redux";
import imgT from "../../Global/images/LogoLogin.png";
import Link from "@mui/material/Link";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

function ColoredBars() {
    return (
        <div>
            <div className="blue-bar" />
            <div className="yellow-bar" />
            <div className="pink-bar" />
        </div>
    );
}

export default function LogIn(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isLoggedin = useSelector(checkIsLoggedIn);
    const isLoginError = useSelector(checkIsLoginError);

    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [passwordVisible, setPasswordVisible] = React.useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(loginUser({ email, password }));
    };
    let password_reset_url = `${process.env.REACT_APP_SECRET_API}/auth/password_reset/`;

    useEffect(() => {
        if (isLoggedin) {
            navigate("/tutorials");
        }
    }, [isLoggedin, isLoginError, navigate]);

    return (
        <>
            <ColoredBars />
            <div className="center-wrapper">
                <div className="center-login">
                    <Box component="form" onSubmit={handleSubmit}>
                        <img src={imgT} alt={"Logo"} />
                        <TextField
                            error={
                                !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
                                    email
                                )
                            }
                            helperText={
                                !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
                                    email
                                )
                                    ? "Veuillez entrer un email valide"
                                    : ""
                            }
                            margin="normal"
                            variant="standard"
                            required
                            fullWidth
                            value={email || ""}
                            onChange={(e) => setEmail(e.target.value)}
                            id="email"
                            label="Email"
                            name="email"
                            autoComplete="email"
                            placeholder="Sensible aux majuscules et minuscules"

                        />
                        <Grid display="flex" alignItems="center">
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            variant="standard"
                            value={password || ""}
                            onChange={(e) => setPassword(e.target.value)}
                            name="password"
                            label="Mot de passe"
                            type={passwordVisible?"text":"password"}
                            id="password"
                            autoComplete="current-password"
                        />
                        {passwordVisible?<VisibilityOffIcon onClick={()=>setPasswordVisible(false)} color="primary"/>:
                         <VisibilityIcon onClick={()=>setPasswordVisible(true)} color="primary"/>}


                        </Grid>
                        <Collapse in={isLoginError}>
                            <Alert
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                    ></IconButton>
                                }
                                sx={{ mb: 2 }}
                                severity="error"
                            >
                               Email ou mot de passe invalide!
                            </Alert>
                        </Collapse>
                        <div
                            style={{
                                marginTop: isLoginError ? "0%" : "10%",
                            }}
                        ></div>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            className="pink-button"
                        >
                            Connexion
                        </Button>
                        <div style={{ marginTop: "10%" }}></div>
                        <Link
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                navigate("/password-reset");
                            }}
                        >
                            Réinitialiser le mot de passe
                        </Link>
                    </Box>

                    <p style={{ marginTop: "50px" }}>
                        Vous n'avez pas de compte?
                    </p>
                    <Link
                        style={{ cursor: "pointer", marginBottom: "50px" }}
                        onClick={() => {
                            navigate("/register");
                        }}
                    >
                        Enregistrer
                    </Link>
                </div>
            </div>
        </>
    );
}
