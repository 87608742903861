import * as React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

export default function TransactionSelect({ transaction, setTransaction }) {
  const transactionList = [
    "à louer",
    "à vendre",
  ];

  const handleChange = (event) => {
    setTransaction(event.target.value);
  };

  React.useEffect(() => {
    setTransaction(transaction);
  }, []);
  
  return (
    <>
      <TransactionSelectDropDown
        transaction={transaction}
        handleChange={handleChange}
        transactionList={transactionList}
      />
    </>
  );
}

function TransactionSelectDropDown({ transaction, handleChange, transactionList }) {
  return (
    <FormControl
      variant="standard"
      fullWidth
      style={{ minWidth: "200px" }}
      sx={{ mt: 1 }}
    >
      <InputLabel>Location / Vente</InputLabel>
      <Select
        value={transaction}
        onChange={handleChange}
        name="transaction"
        placeholder="Location / Vente"
        autoComplete="transaction"
        helperText={"Location / Vente"}
      >
        {transactionList?.map((transaction) => (
          <MenuItem key={transaction} value={transaction}>
            {transaction}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
