import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {
    InputAdornment,
    MenuItem,
    Select,
    TextField,
    Typography,
  } from '@mui/material';
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { checkIsLoggedIn } from "./authSlice";
import { useDispatch, useSelector } from "react-redux";
import imgT from "../../Global/images/LogoLogin.png";
import { useRegisterUserMutation } from "./authAPISlice";
import {
    showSuccess,
    showError,
} from "../../Global/notifications/notificationsSlice";
import CircularProgress from '@mui/material/CircularProgress';
import {
    defaultCountries,
    FlagEmoji,
    parseCountry,
    usePhoneInput,
  } from 'react-international-phone';
import 'react-international-phone/style.css';
import { CellNumberInput } from "../../Components/CellNumberInput";

export default function RegisterPage(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isLoggedin = useSelector(checkIsLoggedIn);

    const [username, setUsername] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [cellNumber, setCellNumber] = React.useState("");

    const [
        registerUser,
        {
            data: registerUserData,
            isLoading: registerUserLoading,
            isSuccess: registerUserSuccess,
            isError: registerUserError,
            error: registerUserMessage,
        },
    ] = useRegisterUserMutation();

    useEffect(() => {
        if (registerUserSuccess) {
            dispatch(showSuccess(registerUserData?.success));
        } else if (registerUserError) {
            dispatch(showError(registerUserMessage?.data.error));
        }
    }, [registerUserSuccess, registerUserError]);

    useEffect(() => {
        if (isLoggedin) {
            navigate("/tutorials");
        }
    }, [isLoggedin]);

    const handleSubmit = (event) => {
        event.preventDefault();
        const body = {
            email: email,
            first_name: username,
            cell_number: cellNumber,
        };
        const request = { body: body };
        registerUser(request)
            .unwrap()
            .then(() => {
                navigate("/login");
            })
            .catch((error) => {});
    };

    if (registerUserLoading) {
        return <LoadingScreen />;
    } else {
        return (
            <>
                <div className="center-wrapper">
                    <div className="center-login">
                        <Box component="form" onSubmit={handleSubmit}>
                            <img src={imgT} alt={"Logo"} />
                            <EmailTextField email={email} setEmail={setEmail} />
                            <UsernameTextField
                                username={username}
                                setUsername={setUsername}
                            />
                            <CellNumberInput
                                value={cellNumber}
                                onChange={setCellNumber}
                            />
                            <p>Vous aller recevoir un email afin de valider votre compte</p>
                            <SubmitButton />
                        </Box>
                    </div>
                </div>
            </>
        );
    }
}

function EmailTextField({ email, setEmail }) {
    return (
        <TextField
            error={
                !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email)
            }
            helperText={
                !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email)
                    ? "Veuillez entrer un email valide"
                    : ""
            }
            margin="normal"
            variant="standard"
            required
            fullWidth
            value={email || ""}
            onChange={(e) => setEmail(e.target.value)}
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            placeholder="Sensible aux majuscules et minuscules"
        />
    );
}

function UsernameTextField({ username, setUsername }) {
    return (
        <TextField
            margin="normal"
            variant="standard"
            required
            fullWidth
            value={username || ""}
            onChange={(e) => setUsername(e.target.value)}
            id="username"
            label="Nom utilisateur"
            name="username"
            autoComplete="username"
        />
    );
}

function CellNumberTextField({ cellNumber, setCellNumber }) {
    return (<></>
    //     <PhoneInput
    //     defaultCountry="fr"
    //     value={cellNumber}
    //     onChange={(cellNumber) => setCellNumber(cellNumber)}
    //   />
        // <TextField
        //     margin="normal"
        //     variant="standard"
        //     required
        //     fullWidth
        //     value={cellNumber || ""}
        //     onChange={(e) => setCellNumber(e.target.value)}
        //     id="cellphoneNumber"
        //     label="Numéro de téléphone"
        //     name="cellphoneNumber"
        //     autoComplete="cellphoneNumber"
        // />
    );
}

function SubmitButton({}) {
    return (
        <Button
            style={{ marginTop: "5%" }}
            type="submit"
            fullWidth
            className="pink-button"
            variant="contained"
        >
            Enregistrer
        </Button>
    );
}

function LoadingScreen({}) {
    return   <Box sx={{ display: 'flex' }} display="flex"
  justifyContent="center"
  alignItems="center"
  minHeight="100vh">
      <CircularProgress />
    </Box>
}
