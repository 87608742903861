import * as React from "react";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import {
  Button,
  Grid,
} from "@mui/material";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";

const drawerBleeding = 56;

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  justifyContent: "center",
  marginTop: " 70px",
  bottom: 0,
  position: "fixed",
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#FBFEFF" : grey[800],
  boxShadow: "12px 12px 12px 12px grey",
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  marginBottom: 2,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

export default function ChangeOrderMenu() {
  const navigate = useNavigate();

  const handleDone = () => {
    navigate(-1);
  };
  return (
    <div>
      <Root>
        <CssBaseline />
        <Global
          styles={{
            ".MuiDrawer-root > .MuiPaper-root": {
              overflow: "visible",
            },
          }}
        />

        
        <StyledBox
          sx={{
            px: 2,
            pb: 2,
            height: "100%",
            marginTop: "10px",
            overflow: "auto",
          }}
        >
          <ButtonsWithinDrawer
            handleDone={handleDone}
       
          />
        </StyledBox>
      </Root>
     
    </div>
  );
}

function ButtonsWithinDrawer({
  handleDone,

}) {
  
  return (
    <Grid
      container
      sx={{ p:2 }}
      gap={2}
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item align="center" xs={12}>
        <Button
          variant="contained"
          className="blue-button"
          onClick={handleDone}
        >
          Valider
        </Button>
      </Grid>
    </Grid>
  );
}
