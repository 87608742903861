import React from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import theme from "../../../Global/theme";

export default function HowToFilm() {
  return <HowToFilmModal />;
}

function HowToFilmModal() {

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    setOpen(true);
  };

  

  return (
    <>
       <h5 style={{ textAlign: "center", mt: 50, color: "#059DB7" }} onClick={handleClick}>
          {" "}
          Comment filmer?
        </h5>

      <Dialog
        BackdropProps={{ invisible: true }}
        style={theme.modalStyle.root}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          <p>Comment filmer?</p>
          <ClearIcon sx={theme.clearIconStyle} onClick={handleClose} />
        </DialogTitle>
        <DialogContent>
        <div>
              <iframe
                width="100%"
                height="300px"
                src="https://www.youtube.com/embed/wDchsz8nmbo?si=GtwjUKkPz7us7RfR"
                frameborder="0"
                allow="autoplay; encrypted-media"
                allowFullScreen="true"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                title="Sample video"
              />{" "}
            </div>


        </DialogContent>
        <DialogActions>
          <Button align="middle" onClick={handleClose}>
            fermer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
