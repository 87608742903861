import * as React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import HomeIcon from "@mui/icons-material/Home";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  Nav,
  Bars,
  NavLink,
  DivLink,
  NavText,
  ProfileLink,
} from "./NavBarElements";
import { logout } from "../../Features/Authentication/authSlice";
import { useDispatch, useSelector } from "react-redux";
import CollectionsIcon from "@mui/icons-material/Collections";
import {
  getnavName,
  getnavDrawer,
} from "../../Features/VideoWorkflow/videoSlice";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Link from "@mui/material/Link";
import {
  getUserProfileAbbreviation,
  getUsername,
  getUserEmail,
} from "../../Features/Authentication/authSlice";
import theme from "../theme";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const drawerWidth = 240;

export default function Navbar(props) {
  const navigate = useNavigate();
  const navbarName = useSelector(getnavName);
  const profileAbbreviatioin = useSelector(getUserProfileAbbreviation);
  const username = useSelector(getUsername);
  const userEmail = useSelector(getUserEmail);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorElLogout, setanchorElLogout] = React.useState(null);

  const handleBack = () => {
    navigate(-1);
  };
  const dispatch = useDispatch();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleCloseDrawer = () => {
    setMobileOpen(false);
  };
  const handleMenuLogout = (event) => {
    setanchorElLogout(event.currentTarget);
  };

  const handleCloseLogout = () => {
    setanchorElLogout(null);
  };

  const handleLogout = () => {
    setanchorElLogout(null);
    dispatch(logout());
  };

  const drawer = (
    <Box
      onClick={handleCloseDrawer}
      sx={{ textAlign: "center", background: "#fff" }}
    >
      <Box
        sx={{
          bgcolor: "#fff",
          height: 50,
          width: 100,
          maxHeight: { xs: 50, md: 100 },
          maxWidth: { xs: 100, md: 200 },
        }}
      />

      <nav aria-label="main mailbox folders">
        <List>
          <ListItem
            style={{ color: "#000" }}
            component={NavLink}
            to="/tutorials"
            reloadDocument
          >
            <ListItemButton>
              <ListItemIcon>
                <HomeIcon sx={{ color: "#000" }} />
              </ListItemIcon>
              <ListItemText primary="Accueil / Tutoriels" />
            </ListItemButton>
          </ListItem>
          <Divider />

          <ListItem
            style={{ color: "#000" }}
            component={NavLink}
            to="/"
            reloadDocument
          >
            <ListItemButton>
              <ListItemIcon>
                <CollectionsIcon sx={{ color: "#000" }} />
              </ListItemIcon>
              <ListItemText primary="Mes projets" />
            </ListItemButton>
          </ListItem>
          <Divider />

          <ListItem
            style={{ color: "#000" }}
            component={NavLink}
            to="/faq"
            reloadDocument
          >
            <ListItemButton>
              <ListItemIcon>
                <ContentPasteIcon sx={{ color: "#000" }} />
              </ListItemIcon>
              <ListItemText primary="FAQ" />
            </ListItemButton>
          </ListItem>
          <Divider />
        </List>
      </nav>
      <LineBreaks />
      <Link
        sx={{ mt: 10 }}
        href="https://linktr.ee/offmarketcommunity"
        color="#0D9BB4"
        target="_blank"
      >
        <OpenInNewIcon /> linktr.ee/offmarketcommunity
      </Link>
      <LineBreaks />
    </Box>
  );

  return (
    <>
      <Box sx={{ mb: "6rem", background: "#fff" }}>
        <Nav>
          <NavText>
            <Typography
              sx={{
                flexGrow: 12,
                fontFamily: "Arial",
                background: "#000",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                ml: 2,
              }}
              textAlign="center"
            >
              {navbarName}
            </Typography>
          </NavText>

          <DivLink>
            {useSelector(getnavDrawer) ? (
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar-left"
                aria-haspopup="true"
                color="inherit"
                onClick={handleDrawerToggle}
                justifycontent="flex-start"
              >
                <Bars sx={{ color: "#000" }} />
              </IconButton>
            ) : (
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar-left"
                aria-haspopup="true"
                color="inherit"
                onClick={handleBack}
                justifycontent="flex-start"
              >
                <ArrowBackIcon sx={{ color: "#000" }} />
              </IconButton>
            )}
          </DivLink>
          <ProfileLink>
            <Button style={theme.profileButton.root} onClick={handleMenuLogout}>
              {profileAbbreviatioin}
            </Button>

            <Menu
              id="menu-appbar-right"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElLogout)}
              onClose={handleCloseLogout}
            >
              <Typography
              sx={{
                flexGrow: 12,
                fontFamily: "Arial",
                color: "#000",
              }}
              textAlign="left">  &nbsp; {username}  &nbsp; </Typography>
              <Typography
              sx={{
                flexGrow: 12,
                fontFamily: "Arial",
                color: "#808080",
              }}
              textAlign="left"> &nbsp; {userEmail}  &nbsp;</Typography>
              <Divider />
              <MenuItem onClick={handleLogout}>
                <LogoutIcon style={{color: "#707070"}} />
                &nbsp; Se déconnecter
              </MenuItem>
            </Menu>
          </ProfileLink>
        </Nav>
        <Box component="nav">
          <Drawer
            open={mobileOpen}
            onClose={handleCloseDrawer}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
      </Box>
    </>
  );
}

function LineBreaks() {
  return (
    <>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </>
  );
}
