import React from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

export default function PlayFinalVideo({ video }) {
    return <PlayFinalVideoModal video={video} />;
}

function PlayFinalVideoModal({ video }) {
   
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const handleClose = () => {
        setOpen(false);
    };

    const handleClick = () => {
        setOpen(true);
    };
    const handleSubmit = () => {
        const request = {
            video_id: video.id,
        };
        // PlayVideo(request);
        navigate(-1);
    };

    return (
        <>
            <PlayArrowIcon style={{ color: "#000" }} onClick={handleClick} />

            <Dialog
                BackdropProps={{ invisible: true }}
                // PaperProps={{
                //   style:theme.modalCardStyle.root,
                // }}
                // style={theme.modalStyle.root}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>{video.name}</DialogTitle>
                <DialogContent>
                    <div className="video-continer">
                        <div className="player-wrapper">
                            {video && <video src={video.video} controls />}
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Back</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
