import * as React from "react";
import Footer from "../../../Global/footer/Footer";
import { Grid } from "@mui/material";
import {
    setnavName,
    setVideoProgress,
    setVideoProgressWarning,
    getVideoProgressWarning,
    setnavDrawer,
} from "../videoSlice";
import { useDispatch } from "react-redux";
import DrawerBottom from "../Video/SpecificVideoMenuWithoutDrawer";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import EditIcon from "@mui/icons-material/Edit";
import { useParams, useNavigate } from "react-router-dom";
import { useGetProjectVideoListQuery, useGetProjectAudioQuery } from "../videoWorkflowAPISlice";
import PlayVideo from "../Video/PlayVideo";
import DeleteVideo from "../Video/DeleteVideo";
import ProgressBarVideo from "../Video/ProgressVideo";
import { useSelector } from "react-redux";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import HowToFilm from "../Video/HowToFilm";
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
export default function SpecificProject() {
    const progressWarning = useSelector(getVideoProgressWarning);
    const navigate = useNavigate();


    const { project_id} = useParams();
    const { name } = useParams();
    const { data: projectAudio, isLoading: getProjectAudioLoading } =
        useGetProjectAudioQuery(project_id);

    const {
        data: projectVideoList,
        isLoading: projectVideoListLoading,
        isSuccess: projectVideoListSuccess,
    } = useGetProjectVideoListQuery(project_id);

    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(setnavName(name));
        dispatch(setnavDrawer(true))
    }, []);

    React.useEffect(() => {
        let temp = 0;
        for (let video in projectVideoList) {
            temp = parseFloat(projectVideoList[video].length) + temp;
            dispatch(setVideoProgress(temp));
            if (temp > 60) {
                dispatch(
                    setVideoProgressWarning(
                        "Vos vidéos dépassent les 60 secondes maximum"
                    )
                );
            } else {
                dispatch(setVideoProgressWarning(""));
            }
        }
    }, [projectVideoListSuccess, projectVideoList]);

    const handleEditProjectVideoClick = (video_id) => {
        navigate(`/project/${project_id}/edit-video/${video_id}/${name}`)
    };

    if (projectVideoListLoading || getProjectAudioLoading) {
        return   <Box sx={{ display: 'flex' }} display="flex"
  justifyContent="center"
  alignItems="center"
  minHeight="100vh">
      <CircularProgress />
    </Box>
    } else if (projectVideoList?.length < 1) {
        return (
            <>
                <Grid container justifyContent="center">
                    <InsertPhotoIcon />
                </Grid>
                <Grid container justifyContent="center">
                    <Grid item xs={12} sx={{p:2}}>
                        <h5 style={{ textAlign: "center", mt: 50}}>
                        Ajoutez une vidéo à votre projet. La vidéo finale ne doit pas dépasser 60 secondes
                        </h5>
                    </Grid>
                    <Grid item xs={12} sx={{mb:10}}>
                       <HowToFilm/>
                       <Footer />
                    </Grid>
                </Grid>
                <DrawerBottom noVideos={true} project_id={project_id} />
              
            </>
        );
    } else {
        return (
            <>
                <Grid
                    container
                    sx={{ mt: 10, mb: 15 }}
                    columns={{ xs: 12 }}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        style={{ margin: "5px" }}
                    >
                        <ProgressBarVideo />
                        <h5 style={{ color: "#FF0000" }}>{progressWarning}</h5>
                    </Grid>
                    {projectVideoList?.map((video) => (
                        <Grid
                            item
                            container
                            sx={{
                                mt: 2,
                                mb: 2,
                                p: 1,
                                borderBottom: "1px solid gray",
                            }}
                            gap={1}
                            columns={{ xs: 12 }}
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            key={video.id}
                        >
                            <Grid item xs={1}>
                                <InsertPhotoIcon fontSize="medium" />
                            </Grid>

                            <Grid item xs={4}>
                                {video.name}
                            </Grid>

                            <Grid item xs={2}>
                                {video.length} s
                            </Grid>

                            <Grid item xs={1}>
                                <PlayVideo video={video} />
                            </Grid>

                            <Grid item xs={1}>
                                <EditIcon onClick={()=>handleEditProjectVideoClick(video.id)} />
                            </Grid>

                            <Grid item xs={1}>
                                <DeleteVideo video={video} />
                            </Grid>
                        </Grid>
                    ))}
                    <EditProjectAudio projectAudio={projectAudio}/>

                    <Grid item xs={12} sx={{mb:15}}>
                  <HowToFilm/>
                  <Footer/>
                </Grid>
                </Grid>

               
                <DrawerBottom noVideos={false} project_id={project_id} />
        
            </>
        );
    }
}

function EditProjectAudio({projectAudio}) {
    const { project_id, name } = useParams();
    const navigate = useNavigate();

    const handleEditProjectAudioClick = () => {
        navigate(`/project/${project_id}/edit-audio/${name}`)
    };

    return (
        <Grid
            item
            container
            sx={{
                mt: 2,
                mb: 2,
                p: 1,
                borderBottom: "1px solid gray",
            }}
            gap={1}
            columns={{ xs: 12 }}
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            <Grid item xs={1} sx={{ textAlign: "left" }}>
                <AudiotrackIcon fontSize="medium" />
            </Grid>

            <Grid item xs={9} sx={{ textAlign: "left" }}>
                {projectAudio.name}
            </Grid>

            <Grid item xs={1} sx={{ textAlign: "right" }}>
                <EditIcon onClick={handleEditProjectAudioClick} />
            </Grid>
        </Grid>
    );
}
