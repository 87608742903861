import React from "react";
import DragAndDropContainer from "./dragAndDropContainer";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { useRef } from "react";
import {

  Grid,
} from "@mui/material";
import ChangeOrderMenu from "./ChangeOrderMenu";
import { useDispatch } from "react-redux";
import { setnavDrawer } from "../../videoSlice";
export default function DragAndReallignVideos() {
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
 const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(setnavDrawer(false));
    }, []);

  if (windowSize.current[0] >= 1400) {
    return (
      <>
        <DndProvider backend={HTML5Backend}>
          <DragAndDropContainer />
        </DndProvider>
        <Grid
          container
          gap={2}
          sx={{ p: 4 }}
          columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item align="center" xs={12} sx={{ mt: -5, mb:15 }}>
            <h6> Glissez-déposez pour modifier l'ordre </h6>
          </Grid>
        </Grid>
      </>
    );
  } else {
    return (
      <>
        <DndProvider backend={TouchBackend}>
          <DragAndDropContainer />
        </DndProvider>
        <Grid
          container
          gap={2}
          sx={{ p: 4 }}
          columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item align="center" xs={12} sx={{ mt: -5, mb:15 }}>
            <h6> Glissez-déposez pour modifier l'ordre </h6>
          </Grid>
        </Grid>
        <ChangeOrderMenu/>
      </>
    );
  }
}
