import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSlice";
const authAdapter = createEntityAdapter();

const initialState = authAdapter.getInitialState();

export const authAPISlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        registerUser: builder.mutation({
            query: (request) => ({
                url: `${process.env.REACT_APP_SECRET_API}/auth/register/`,
                method: "POST",
                body: request.body,
            }),
            invalidatesTags: ["register_user"],
        }),

        getRegisterValidateToken: builder.query({
            query: (key) => ({
                url: `${process.env.REACT_APP_SECRET_API}/auth/register/${key}/`,
            }),
            providesTags: ["register_user"],
        }),
        setRegisterUserPassword: builder.mutation({
            query: (request) => ({
                url: `${process.env.REACT_APP_SECRET_API}/auth/register/${request.key}/`,
                method: "POST",
                body: request.body,
            }),
            invalidatesTags: ["register_user"],
        }),

        requestPasswordReset: builder.mutation({
            query: (request) => ({
                url: `${process.env.REACT_APP_SECRET_API}/auth/password-reset/`,
                method: "POST",
                body: request.body,
            }),
            invalidatesTags: ["password_reset"],
        }),
        getPasswordResetValidateToken: builder.query({
            query: (key) => ({
                url: `${process.env.REACT_APP_SECRET_API}/auth/password-reset/${key}/`,
            }),
            providesTags: ["password_reset"],
        }),
        resetUserPassword: builder.mutation({
            query: (request) => ({
                url: `${process.env.REACT_APP_SECRET_API}/auth/password-reset/${request.key}/`,
                method: "POST",
                body: request.body,
            }),
            invalidatesTags: ["password_reset"],
        }),
    }),
});

export const {
    useRegisterUserMutation,
    useGetRegisterValidateTokenQuery,
    useSetRegisterUserPasswordMutation,
    useRequestPasswordResetMutation,
    useGetPasswordResetValidateTokenQuery,
    useResetUserPasswordMutation,
} = authAPISlice;
