import * as React from "react";
import { useParams } from "react-router-dom";
import { useGetPublicFinalVideoQuery } from "./videoWorkflowAPISlice";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
export default function ViewPublicFinal() {
    const { pub_key } = useParams();

    const {
        data: publicFinalVideo,
        isLoading: publicFinalVideoLoading,
        isError: publicFinalVideoError,
    } = useGetPublicFinalVideoQuery(pub_key);

    if (publicFinalVideoLoading) {
        return   <Box sx={{ display: 'flex' }} display="flex"
  justifyContent="center"
  alignItems="center"
  minHeight="100vh">
      <CircularProgress />
    </Box>
    } else if (publicFinalVideoError) {
        return <h1>404 Not Video Not Found</h1>;
    } else {
        return (
            <div className="video-continer">
                <div className="player-wrapper">
                    <video src={publicFinalVideo?.video} controls />
                </div>
            </div>
        );
    }
}
