import { configureStore } from "@reduxjs/toolkit";
import authenticationReducer from "./Features/Authentication/authSlice";
import { apiSlice } from "./Features/api/apiSlice";
import notificationsReducer from "./Global/notifications/notificationsSlice";
import videoReducer from "./Features/VideoWorkflow/videoSlice";
function check_if_production() {
    if (process.env.REACT_APP_PRODUCTION === 1) {
        return false;
    } else {
        return true;
    }
}

export const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        auth: authenticationReducer,
        notifications: notificationsReducer,
        video: videoReducer,
    },
    devTools: check_if_production(),
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(apiSlice.middleware),
});
