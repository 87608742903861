import * as React from "react";
import PauseIcon from "@mui/icons-material/Pause";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { useParams, useNavigate } from "react-router-dom";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import { Button, Grid, Box } from "@mui/material";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";

export default function EditAudioSelect({
  trackList,
  setSelectAudio,
  projectAudio,
  handleSubmit,
}) {
  const [selectedOption, setSelectedOption] = React.useState(projectAudio?.id);
  const [selectedAudioURL, setSelectedAudioURL] = React.useState(
    projectAudio?.audio
  );
  const [audioPlaying, setAudioPlaying] = React.useState(false);
  const audioRef = React.useRef(null);

  const handleChange = (event) => {
    setAudioPlaying(false);
    setSelectedOption(event.target.value);
    setSelectAudio(event.target.value);
    setSelectedAudioURL(getLabelById(event.target.value));
  };

  const handlePlayClick = () => {
    if (audioPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setAudioPlaying(!audioPlaying);
  };

  const getLabelById = (id) => {
    const trackURL = trackList.find((track) => track.id === id);
    return trackURL.audio;
  };

  React.useEffect(() => {
    setSelectAudio(projectAudio?.id);
  }, [trackList]);

  return (
    <>
      <div className="center-audio-select">
        <TrackSelect
          selectedOption={selectedOption}
          handleChange={handleChange}
          trackList={trackList}
        />
        <PlayPauseButton
          handlePlayClick={handlePlayClick}
          audioPlaying={audioPlaying}
        />
        <AudioPlayer audioRef={audioRef} selectedAudioURL={selectedAudioURL} />
      </div>
      <DrawerBottom handleSubmit={handleSubmit} />
    </>
  );
}

function AudioPlayer({ audioRef, selectedAudioURL }) {
  return (
    <audio
      ref={audioRef}
      style={{ display: "none" }}
      src={selectedAudioURL}
    ></audio>
  );
}

function PlayPauseButton({ handlePlayClick, audioPlaying }) {
  return (
    <IconButton
      style={{
        boxShadow: "2px 3px 8px #888888",
        border: "1px solid #ccc",
        borderStyle: "solid",
        borderRadius: "4px",
        padding: "15px 15px",
      }}
      onClick={handlePlayClick}
    >
      {audioPlaying ? <PauseIcon /> : <PlayArrowIcon />}
    </IconButton>
  );
}

function TrackSelect({ selectedOption, handleChange, trackList }) {
  return (
    <FormControl fullWidth style={{ minWidth: "200px" }}>
      <InputLabel>Musique de fond</InputLabel>
      <Select value={selectedOption} onChange={handleChange} label="Musique de fond">
        {trackList?.map((track) => (
          <MenuItem key={track.id} value={track.id}>
            {track.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

const drawerBleeding = 56;

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  justifyContent: "center",
  marginTop: " 70px",
  bottom: 0,
  position: "fixed",
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#FBFEFF" : grey[800],
  boxShadow: "12px 12px 12px 12px grey",
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  marginBottom: 2,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

function DrawerBottom({ handleSubmit }) {
  return (
    <Root>
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            overflow: "visible",
          },
        }}
      />



      <StyledBox
        sx={{
          px: 2,
          pb: 2,
          height: "100%",
          marginTop: "10px",
          overflow: "auto",
        }}
      >
        <ButtonsWithinDrawer
          handleSubmit={handleSubmit}
        />
      </StyledBox>
    </Root>
  );
}

function ButtonsWithinDrawer({  handleSubmit }) {
  const { project_id, name } = useParams();
  const navigate = useNavigate();
  const handleCancel = () => {
    navigate(`/project/${project_id}/${name}`);
  };

  return (
    <Grid
      container
      sx={{ p: 1 }}
      gap={2}
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item align="center" xs={12}>
        <Button
          variant="contained"
          className="pink-button"
          onClick={handleSubmit}
        >
          Continuer
        </Button>
      </Grid>

      <Grid item align="center" xs={12}>
        <Button
          variant="contained"
          className="yellow-button"
          onClick={handleCancel}
        >
          Annuler
        </Button>
      </Grid>
    </Grid>
  );
}
