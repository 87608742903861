import * as React from "react";
import PauseIcon from "@mui/icons-material/Pause";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

export default function AudioSelect({ trackList, setSelectAudio }) {

    const [selectedOption, setSelectedOption] = React.useState(
        trackList[0]?.id
    );
    const [selectedAudioURL, setSelectedAudioURL] = React.useState(
        trackList[0]?.audio
    );
    const [audioPlaying, setAudioPlaying] = React.useState(false);
    const audioRef = React.useRef(null);

    const handleChange = (event) => {
        setAudioPlaying(false);
        setSelectedOption(event.target.value);
        setSelectAudio(event.target.value);
        setSelectedAudioURL(getLabelById(event.target.value));
    };

    const handlePlayClick = () => {
        if (audioPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }
        setAudioPlaying(!audioPlaying);
    };

    const getLabelById = (id) => {
        const trackURL = trackList.find((track) => track.id === id);
        return trackURL.audio;
    };

    React.useEffect(() => {
        setSelectAudio(trackList[0]?.id);
    }, [trackList]);

    return (
        <>
            <div className="center-audio-select">
                <TrackSelect
                    selectedOption={selectedOption}
                    handleChange={handleChange}
                    trackList={trackList}
                />
                <PlayPauseButton
                    handlePlayClick={handlePlayClick}
                    audioPlaying={audioPlaying}
                />
                <AudioPlayer
                    audioRef={audioRef}
                    selectedAudioURL={selectedAudioURL}
                />
            </div>
        </>
    );
}

function AudioPlayer({ audioRef, selectedAudioURL }) {
    return (
        <audio
            ref={audioRef}
            style={{ display: "none" }}
            src={selectedAudioURL}
        ></audio>
    );
}

function PlayPauseButton({ handlePlayClick, audioPlaying }) {
    return (
        <IconButton
            style={{
                boxShadow: "2px 3px 8px #888888",
                border: "1px solid #ccc",
                borderStyle: "solid",
                borderRadius: "4px",
                padding: "15px 15px",
            }}
            onClick={handlePlayClick}
        >
            {audioPlaying ? <PauseIcon /> : <PlayArrowIcon />}
        </IconButton>
    );
}

function TrackSelect({ selectedOption, handleChange, trackList }) {
    return (
        <FormControl fullWidth style={{ minWidth: "200px" }}>
            <InputLabel>Musique de fond</InputLabel>
            <Select
                value={selectedOption}
                onChange={handleChange}
                label="Musique de fond"
            >
                {trackList?.map((track) => (
                    <MenuItem key={track.id} value={track.id}>
                        {track.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
