import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { checkIsLoggedIn } from "./authSlice";
import { useSelector, useDispatch } from "react-redux";
import { useRequestPasswordResetMutation } from "./authAPISlice";
import imgT from "../../Global/images/LogoLogin.png";
import {
    showSuccess,
    showError,
} from "../../Global/notifications/notificationsSlice";
import CircularProgress from '@mui/material/CircularProgress';
export default function RequestPasswordResetPage(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isLoggedin = useSelector(checkIsLoggedIn);
    const [email, setEmail] = React.useState("");

    const [
        requestPasswordReset,
        {
            data: requestPasswordResetData,
            isLoading: requestPasswordResetLoading,
            isSuccess: requestPasswordResetSuccess,
            isError: requestPasswordResetError,
            error: requestPasswordResetMessage,
        },
    ] = useRequestPasswordResetMutation();

    const handleSubmit = (event) => {
        event.preventDefault();
        const body = {
            email: email,
        };
        const request = {
            body: body,
        };
        requestPasswordReset(request)
            .unwrap()
            .then(() => {
                navigate("/login");
            })
            .catch((error) => {});
    };

    useEffect(() => {
        if (isLoggedin) {
            navigate("/tutorials");
        }
        if (requestPasswordResetSuccess) {
            dispatch(showSuccess(requestPasswordResetData?.success));
        } else if (requestPasswordResetError) {
            dispatch(showError(requestPasswordResetMessage?.data?.error));
        }
    }, [
        isLoggedin,
        navigate,
        requestPasswordResetSuccess,
        requestPasswordResetError,
    ]);
    if (requestPasswordResetLoading) {
        return   <Box sx={{ display: 'flex' }} display="flex"
  justifyContent="center"
  alignItems="center"
  minHeight="100vh">
      <CircularProgress />
    </Box>
    } else {
        return (
            <>
                <div className="center-wrapper">
                    <div className="center-login">
                        <Box component="form" onSubmit={handleSubmit}>
                            <img src={imgT} alt={"Logo"} />
                            <TextField
                                error={
                                    !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
                                        email
                                    )
                                }
                                helperText={
                                    !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
                                        email
                                    )
                                        ? "Veuillez entrer un email valide"
                                        : ""
                                }
                                margin="normal"
                                variant="standard"
                                required
                                fullWidth
                                value={email || ""}
                                onChange={(e) => setEmail(e.target.value)}
                                id="email"
                                label="Email"
                                name="email"
                                autoComplete="email"
                                placeholder="Sensible aux majuscules et minuscules"

                            />
                            <Button
                                style={{ marginTop: "5%" }}
                                type="submit"
                                fullWidth
                                className="pink-button"
                                variant="contained"
                            >
                                Demander la réinitialisation du mot de passe
                            </Button>
                        </Box>
                    </div>
                </div>
            </>
        );
    }
}
