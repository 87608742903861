import * as React from "react";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import { Button, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Drawer from "@mui/material/Drawer";

import { useNavigate } from "react-router-dom";
import GenerateLink from "../../FinalVideo/OpenGenerateLink";
import DeleteProject from "./DeleteProject";

const Root = styled("div")(({ theme }) => ({
  height: "100%",
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
}));

export default function DrawerBottom({ id, name, share, link }) {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  return (
    <Root>
      <CssBaseline />
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            overflow: "visible",
          },
        }}
      />

      <MoreHorizIcon
        fontSize="large"
        style={{ backgroundColor: "#dbdbdb" }}
        sx={{ color: "#000", background: "#dbdbdb" }}
        onClick={toggleDrawer(true)}
      />

      <Drawer
        anchor="bottom"
        open={open}
        onClose={toggleDrawer(false)}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <StyledBox
          sx={{
            position: "absolute",
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: "visible",
            right: 0,
            left: 0,
          }}
        ></StyledBox>
        <StyledBox
          sx={{
            px: 2,
            pb: 2,
            height: "100%",
            marginTop: "10px",
            overflow: "auto",
          }}
        >
          <ButtonsWithinDrawer
            toggleDrawer={toggleDrawer}
            id={id}
            name={name}
            share={share}
            link={link}
          />
        </StyledBox>
      </Drawer>
    </Root>
  );
}

function ButtonsWithinDrawer({ toggleDrawer, id, name, share, link }) {
  const navigate = useNavigate();

  const goToProject = () => {
    navigate(`/project/${id}/${name}/`);
  };

  const editProject = () => {
    navigate(`/project/edit/${id}/`);
  };
  const color = "pink";
  return (
    <Grid
      container
      sx={{ p: 1 }}
      gap={2}
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item align="center" xs={12}>
        <Button
          variant="contained"
          className="pink-button"
          onClick={goToProject}
        >
          aller au projet
        </Button>
      </Grid>

      <Grid item align="center" xs={12}>
        <GenerateLink link={link} share={share} color={color} name="Partager" />
      </Grid>

      <Grid item align="center" xs={12}>
        <DeleteProject id={id} />
      </Grid>

      <Grid item align="center" xs={12}>
        <Button
          variant="contained"
          className="pink-button"
          onClick={editProject}
        >
          modifier
        </Button>
      </Grid>

      <Grid item align="center" xs={12}>
        <Button
          variant="contained"
          className="yellow-button"
          onClick={toggleDrawer(false)}
        >
          Annuler
        </Button>
      </Grid>
    </Grid>
  );
}
