import React from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useState } from "react";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

export default function PlayVideo({ video }) {
    return <PlayVideoModal video={video} />;
}

function PlayVideoModal({ video }) {

    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };

    const handleClick = () => {
        setOpen(true);
    };


    return (
        <>
            <PlayArrowIcon onClick={handleClick} />

            <Dialog
                BackdropProps={{ invisible: true }}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>{video.name}</DialogTitle>
                <DialogContent>
                    <div className="video-continer">
                        <div className="player-wrapper">
                            {video && <video src={video.video} controls />}
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Back</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
