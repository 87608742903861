import * as React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

export default function TypeSelect({ type, setType }) {
  const typeList = [
    "Appartement",
    "Maison",
    "Terrain",
    "Parking/Box",
    "Loft/Atelier",
    "Immeuble",
    "Divers",
  ];

  const handleChange = (event) => {
    setType(event.target.value);
  };

  React.useEffect(() => {
    setType(type);
  }, []);

  return (
    <>
      <TypeSelectDropDown
        type={type}
        handleChange={handleChange}
        typeList={typeList}
      />
    </>
  );
}

function TypeSelectDropDown({ type, handleChange, typeList }) {
  return (
    <FormControl
      variant="standard"
      fullWidth
      style={{ minWidth: "200px" }}
      sx={{ mt: 1 }}
    >
      <InputLabel>Type de bien</InputLabel>
      <Select
        value={type}
        onChange={handleChange}
        name="type"
        placeholder="Type de bien"
        autoComplete="type"
        // helperText={"Type de bien"}
      >
        {typeList?.map((type) => (
          <MenuItem key={type} value={type}>
            {type}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
