import update from "immutability-helper";
import { memo, useCallback, useState, useEffect } from "react";
import { useDrop } from "react-dnd";
import { Card } from "./Card.js";
import { ItemTypes } from "./ItemTypes.js";
import {
  useGetProjectVideoListQuery,
  useEditVideoMutation,
} from "../../videoWorkflowAPISlice.js";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import { useParams } from "react-router-dom";

const style = {
  alignItems: "center",
  justifyContext: "center",
};
const ITEMS = [
  {
    id: 0,
    order: 1,
    text: "Loading videos",
  },
];

export const Container = memo(function Container() {
  const [cards, setCards] = useState(ITEMS);
  const { project_id } = useParams();

  const {
    data: projectVideoList,
    isSuccess: projectVideoListSuccess,
  } = useGetProjectVideoListQuery(project_id);
  const [
    editOrder,
    {},
  ] = useEditVideoMutation();

  useEffect(() => {
    setCards(projectVideoList);
  }, [projectVideoListSuccess, projectVideoList]);

  useEffect(() => {
    let count = 1;
    for (let video in cards) {
      const body = {
        order: count,
      };
      const request = {
        body: body,
        project_id: project_id,
        video_id: cards[video].id,
      };
      editOrder(request);
      count++;
    }
  }, [cards]);
  const findCard = useCallback(
    (order) => {
      const card = cards.filter((c) => `${c.order}` === order)[0];
        return {
        card,
        index: cards.indexOf(card),
      };
    },
    [cards]
  );
  const moveCard = useCallback(
    (order, atIndex) => {
      const { card, index } = findCard(order);

      setCards(
        update(cards, {
          $splice: [
            [index, 1],
            [atIndex, 0, card],
          ],
        })
      );
    },
    [findCard, cards, setCards]
  );
  const [, drop] = useDrop(() => ({ accept: ItemTypes.CARD }));
  return (
    <div ref={drop} style={style}>
      {cards?.map((card) => (
        <Card
          key={card.order}
          order={`${card.order}`}
          text={card.name}
          length={card.length}
          icon={<InsertPhotoIcon />}
          moveCard={moveCard}
          findCard={findCard}
        />
      ))}
    </div>
  );
});