import React from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import theme from "../../../Global/theme";
import { useDeleteProjectMutation } from "../videoWorkflowAPISlice";
import { useParams } from "react-router-dom";

export default function DeleteProject({ id }) {
  return <DeleteProjectModal id={id} />;
}

function DeleteProjectModal({ id }) {
  const [deleteProject] = useDeleteProjectMutation();

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleSubmit = () => {
    const request = {
      project_id: id,
    };
    deleteProject(request);
  };

  return (
    <>
      <Button variant="contained" className="pink-button" onClick={handleClick}>
        Supprimer
      </Button>

      <Dialog
        BackdropProps={{ invisible: true }}
        style={theme.modalStyle.root}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          <p>Êtes-vous sûr de vouloir supprimer ce projet?</p>
          <ClearIcon sx={theme.clearIconStyle} onClick={handleClose} />
        </DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions>
          <Button align="middle" onClick={handleSubmit}>
            oui, supprime
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
