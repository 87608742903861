import * as React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { getVideoProgress } from '../videoSlice';
import { useSelector } from "react-redux";

function LinearProgressWithLabel(props) {
  const normalise = (value) => ((value - 0) * 100) / (60 - 0);

  return (

    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%' }}>
        <LinearProgress color={normalise(props.value)<100?"primary":"error"} variant="determinate" value={normalise(props.value)>100?100:normalise(props.value)} />
      </Box>
      <Box >
        <Typography variant="body2" color="text.secondary">
            {`${Math.round(
          props.value,
        )}s/60s`}
        </Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {

  value: PropTypes.number.isRequired,
};

export default function ProgressBarVideo() {

  const progress = useSelector(getVideoProgress);
  React.useEffect(() => {

  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgressWithLabel value={progress}  />
    </Box>
  );
}