import * as React from "react";
import Footer from "../../../Global/footer/Footer";
import { Button } from "@mui/material";
import { setnavDrawer, setnavName } from "../videoSlice";
import { useDispatch, useSelector } from "react-redux";
import {
    InputAdornment,
    MenuItem,
    Select,
    TextField,
    Typography,
  } from '@mui/material';
import Box from "@mui/material/Box";
import { useGetAudioListQuery } from "../videoWorkflowAPISlice";
import AudioSelect from "./AudioSelect";
import {
  getUserEmail,
  getUserContactNumber,
} from "../../Authentication/authSlice";
import {
  showError,
  showSuccess,
} from "../../../Global/notifications/notificationsSlice";
import { useCreateNewProjectMutation } from "../videoWorkflowAPISlice";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import TypeSelect from "./TypeSelect";
import { CellNumberInput } from "../../../Components/CellNumberInput";

import TransactionSelect from "./TransactionSelect";
export default function NewProject() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userEmail = useSelector(getUserEmail);
  const userContactNumber = useSelector(getUserContactNumber);

  const [projectName, setProjectName] = React.useState("");
  const [type, setType] = React.useState("");
  const [transaction, setTransaction] = React.useState("");
  const [numberOfRooms, setnumberOfRooms] = React.useState("");
  const [numberOfBathrooms, setNumberOfBathrooms] = React.useState("");
  const [area, setArea] = React.useState("");
  const [city, setCity] = React.useState("");
  const [postcode, setPostcode] = React.useState("");
  const [email, setEmail] = React.useState(userEmail);
  const [price, setPrice] = React.useState("");
  const [contactNumber, setContactNumber] = React.useState(userContactNumber);
  const [selectedAudio, setSelectAudio] = React.useState(1);

  const { data: audioList, isLoading: getAudioListLoading } =
    useGetAudioListQuery();

  const [
    createProject,
    {
      data: createProjectData,
      isLoading: createProjectLoading,
      isSuccess: createProjectSuccess,
      isError: createProjectError,
      error: createProjectMessage,
    },
  ] = useCreateNewProjectMutation();

  React.useEffect(() => {
    dispatch(setnavName("Nouveau projet"));
    dispatch(setnavDrawer(false));
  }, []);
  React.useEffect(() => {
    if (createProjectSuccess) {
      dispatch(showSuccess(createProjectData?.success));
    } else if (createProjectError) {
      dispatch(showError(createProjectMessage?.data.error));
    }
  }, [createProjectSuccess, createProjectError]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const body = {
      name: projectName,
      type: type,
      transaction: transaction,
      no_rooms: numberOfRooms,
      no_bathrooms: numberOfBathrooms,
      area: area,
      city: city,
      postcode: postcode,
      price: price,
      email: email,
      contact_number: contactNumber,
      audio: selectedAudio,
    };
    const request = { body: body };
    createProject(request)
      .unwrap()
      .then((payload) => {
        dispatch(setnavName(`Projet ${payload.data.name}`));
        navigate(`/project/${payload.data.id}/${payload.data.name}`);
      })
      .catch((error) => {});
  };

  if (getAudioListLoading || createProjectLoading) {
    return (
      <Box
        sx={{ display: "flex" }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  } else {
    return (
      <>
        <div className="center-wrapper">
          <Box component="form" onSubmit={handleSubmit}>
            <div className="center-form-elements">
              <ProjectNameTextField
                projectName={projectName}
                setProjectName={setProjectName}
              />
              <TypeSelect type={type} setType={setType} />
              <TransactionSelect
                transaction={transaction}
                setTransaction={setTransaction}
              />
              <RoomsTextField
                numberOfRooms={numberOfRooms}
                setnumberOfRooms={setnumberOfRooms}
              />
              <BathroomsTextField
                numberOfBathrooms={numberOfBathrooms}
                setNumberOfBathrooms={setNumberOfBathrooms}
              />
              <AreaTextField area={area} setArea={setArea} />
              <CityTextField city={city} setCity={setCity} />
              <PostcodeTextField
                postcode={postcode}
                setPostcode={setPostcode}
              />
              <PriceTextField price={price} setPrice={setPrice} />
              <EmailTextField email={email} setEmail={setEmail} />
              {/* <ContactNumberTextField
                contactNumber={contactNumber}
                setContactNumber={setContactNumber}
              /> */}
              <CellNumberInput
              value={contactNumber}
              onChange={setContactNumber}/>
              <AudioSelect
                trackList={audioList}
                setSelectAudio={setSelectAudio}
              />
              <SubmitButton />
            </div>
          </Box>
        </div>
        <Footer />
      </>
    );
  }
}

function ProjectNameTextField({ projectName, setProjectName }) {
  return (
    <TextField
      margin="normal"
      variant="standard"
      required
      fullWidth
      value={projectName || ""}
      onChange={(e) => setProjectName(e.target.value)}
      id="projectName"
      label="Nom du projet"
      name="projectName"
      autoComplete="projectName"
    />
  );
}

function RoomsTextField({ numberOfRooms, setnumberOfRooms }) {
  return (
    <TextField
      margin="normal"
      variant="standard"
      required
      fullWidth
      value={numberOfRooms || ""}
      onChange={(e) => setnumberOfRooms(e.target.value)}
      id="numberOfRooms"
      placeholder="1, 2, 3, etc"
      label="Nombre de pièce(s)"
      name="numberOfRooms"
      autoComplete="numberOfRooms"
    />
  );
}
function BathroomsTextField({ numberOfBathrooms, setNumberOfBathrooms }) {
  return (
    <TextField
      margin="normal"
      variant="standard"
      required
      fullWidth
      value={numberOfBathrooms || ""}
      onChange={(e) => setNumberOfBathrooms(e.target.value)}
      id="numberOfBathrooms"
      placeholder="1, 2, 3, etc"
      label="Nombre de chambre(s)"
      name="numberOfBathrooms"
      autoComplete="numberOfBathrooms"
    />
  );
}
function AreaTextField({ area, setArea }) {
  return (
    <TextField
      margin="normal"
      variant="standard"
      required
      fullWidth
      value={area || ""}
      onChange={(e) => setArea(e.target.value)}
      id="area"
      label="Surface (m&sup2;)"
      name="area"
      autoComplete="area"
    />
  );
}
function CityTextField({ city, setCity }) {
  return (
    <TextField
      margin="normal"
      required
      fullWidth
      variant="standard"
      value={city || ""}
      onChange={(e) => setCity(e.target.value)}
      name="city"
      label="Ville"
      type="city"
      id="city"
      autoComplete="city"
    />
  );
}
function PostcodeTextField({ postcode, setPostcode }) {
  return (
    <TextField
      margin="normal"
      variant="standard"
      required
      fullWidth
      value={postcode || ""}
      onChange={(e) => setPostcode(e.target.value)}
      id="postcode"
      label="Code postal"
      name="postcode"
      autoComplete="postcode"
    />
  );
}
function PriceTextField({ price, setPrice }) {
  return (
    <TextField
      margin="normal"
      variant="standard"
      required
      fullWidth
      value={price || ""}
      onChange={(e) => setPrice(e.target.value)}
      id="price"
      placeholder="1000"
      label="Prix (€)"
      name="price"
      autoComplete="price"
    />
  );
}
function EmailTextField({ email, setEmail }) {
  return (
    <TextField
      error={!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email)}
      helperText={
        !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email)
          ? "Veuillez entrer un email valide"
          : ""
      }
      margin="normal"
      variant="standard"
      required
      fullWidth
      value={email || ""}
      onChange={(e) => setEmail(e.target.value)}
      id="email"
      label="Email"
      name="email"
      autoComplete="email"
    />
  );
}
function ContactNumberTextField({ contactNumber, setContactNumber }) {
  return (
    <TextField
      margin="normal"
      variant="standard"
      required
      fullWidth
      value={contactNumber || ""}
      onChange={(e) => setContactNumber(e.target.value)}
      id="contactNumber"
      label="Téléphone"
      name="contactNumber"
      autoComplete="contactNumber"
    />
  );
}

function SubmitButton() {
  return (
    <Button type="submit" fullWidth variant="contained" className="pink-button">
      Demarrer le projet
    </Button>
  );
}
