import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
// import { store } from "../state/store";
import { store } from "../store";
import theme from "../Global/theme";
import { ThemeProvider } from "@mui/material/styles";
import MainRouter from "../Global/routing/MainRouter";
import "../index.css"

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <MainRouter />
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
