import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSlice";
const videoWorkflowAdapter = createEntityAdapter();

const initialState = videoWorkflowAdapter.getInitialState();

export const videoWorkflowAPISlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({

        getAudioList: builder.query({
            query: () => ({
                url: `${process.env.REACT_APP_SECRET_API}/video/audio/`,
            }),
            providesTags: ["audio_list"],
        }),
        getProjectAudio: builder.query({
            query: (project_id) => ({
                url: `${process.env.REACT_APP_SECRET_API}/video/project/${project_id}/audio/`,
            }),
            providesTags: ["selected_audio"],
        }),
        updateProjectAudio: builder.mutation({
            query: (request) => ({
                url: `${process.env.REACT_APP_SECRET_API}/video/project/${request.project_id}/audio/`,
                method: "PATCH",
                body: request.body,
            }),
            invalidatesTags: ["selected_audio"],
        }),
        getProjects: builder.query({
            query: () => ({
              url: `${process.env.REACT_APP_SECRET_API}/video/project/`,

            }),
            providesTags: ["create_project"],
          }),
        createNewProject: builder.mutation({
            query: (request) => ({
                url: `${process.env.REACT_APP_SECRET_API}/video/project/`,
                method: "POST",
                body: request.body,
            }),
            invalidatesTags: ["create_project"],
        }),
        getProjectVideoList: builder.query({
            query: (project_id) => ({
                url: `${process.env.REACT_APP_SECRET_API}/video/project/${project_id}/video/`,
            }),
            providesTags: ["project_video_list", "video"],
        }),
        getVideo: builder.query({
            query: ({project_id, video_id}) => ({
                url: `${process.env.REACT_APP_SECRET_API}/video/project/${project_id}/video/${video_id}/edit/`
            }),
            providesTags: ["project_video_list", "video"],
        }),
        uploadVideo: builder.mutation({
            query: (request) => ({
                url: `${process.env.REACT_APP_SECRET_API}/video/project/${request.project_id}/video/`,
                method: "POST",
                body: request.body,
            }),
            invalidatesTags: ["project_video_list"],
        }),
        getFinalVideo: builder.query({
            query: (project_id) => ({
                url: `${process.env.REACT_APP_SECRET_API}/video/project/${project_id}/final-video/`,
            }),
            providesTags: ["final_video"],
        }),
        generateVideo: builder.mutation({
            query: (request) => ({
                url: `${process.env.REACT_APP_SECRET_API}/video/project/${request.project_id}/final-video/`,
                method: "POST",
            }),
            invalidatesTags: ["final_video"],
        }),
        getFinalVideoProgress: builder.query({
            query: (project_id) => ({
                url: `${process.env.REACT_APP_SECRET_API}/video/project/${project_id}/final-video-progress/`,
            }),
            providesTags: ["final_video"],
        }),
        editVideo: builder.mutation({
            query: (request) => ({
                url: `${process.env.REACT_APP_SECRET_API}/video/project/${request.project_id}/video/${request.video_id}/edit/`,
                method: "PATCH",
                body: request.body,
            }),
            invalidatesTags: ["project_video_list", "video"],
        }),
        deleteVideo: builder.mutation({
            query: (request) => ({
                url: `${process.env.REACT_APP_SECRET_API}/video/project/${request.project_id}/video/${request.video_id}/edit/`,
                method: "DELETE",
                body: request.body,
            }),
            invalidatesTags: ["project_video_list", "video"],
        }),
        getPublicFinalVideo: builder.query({
            query: (pub_key) => ({
                url: `${process.env.REACT_APP_SECRET_API}/video/project/${pub_key}/view-pub-final/`,
            }),
            providesTags: ["final_public_video"],
        }),
        editProject: builder.mutation({
            query: (request) => ({
                url: `${process.env.REACT_APP_SECRET_API}/video/project/${request.project_id}/edit/`,
                method: "PATCH",
                body: request.body,
            }),
            invalidatesTags: ["create_project","project"],
        }),
        deleteProject: builder.mutation({
            query: (request) => ({
                url: `${process.env.REACT_APP_SECRET_API}/video/project/${request.project_id}/edit/`,
                method: "DELETE",
                body: request.body,
            }),
            invalidatesTags: ["create_project","project"],
        }),
        getProject: builder.query({
            query: (project_id) => ({
              url: `${process.env.REACT_APP_SECRET_API}/video/project/${project_id}/edit/`,

            }),
            providesTags: ["project"],
          }),
    }),
});

export const {
    useGetAudioListQuery,
    useGetProjectAudioQuery,
    useUpdateProjectAudioMutation,
    useGetProjectsQuery,
    useCreateNewProjectMutation,
    useGetFinalVideoProgressQuery,
    useGetProjectVideoListQuery,
    useGetVideoQuery,
    useUploadVideoMutation,
    useGenerateVideoMutation,
    useEditVideoMutation,
    useDeleteVideoMutation,
    useGetPublicFinalVideoQuery,
    useEditProjectMutation,
    useDeleteProjectMutation,
    useGetProjectQuery,
    useGetFinalVideoQuery
} = videoWorkflowAPISlice;
