import * as React from "react";
import Footer from "../../Global/footer/Footer";
import { Button, Grid } from "@mui/material";
import { setnavDrawer, setnavName } from "../VideoWorkflow/videoSlice";
import { useDispatch } from "react-redux";
import { useGetTutorialVideosQuery } from "../api/apiSlice";
import { useNavigate } from "react-router-dom";

export default function TutorialsPage() {
  const navigate = useNavigate()
  const handleHome = () => {
   navigate("/");
  };
  const handleNewProject = () => {
    navigate("/project");
   };
  const dispatch = useDispatch();
  dispatch(setnavName("Accueil / Tutoriels"));
  dispatch(setnavDrawer(true))
  const {
    data: tutorials,
    isLoading: tutorialsLoading,
    isError: tutorialsError,
  } = useGetTutorialVideosQuery();

  if (tutorialsLoading || tutorialsError) {
    return (
      <>
        <TutorialsButtons />
        <Footer />
      </>
    );
  } else {
    return (
      <>
        <TutorialsButtons handleHome={handleHome} handleNewProject={handleNewProject} />
        <TutorialVideosMapped tutorials={tutorials} />
        <Footer />
      </>
    );
  }
}

function TutorialsButtons({handleHome, handleNewProject}) {
  return (
    <Grid
      container
      sx={{ mt: 2, mb: 1, p: 2 }}
      columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Grid container justifyContent="left" xs={6} md={3}>
        <Button onClick={handleHome} sx={{ color: "#0499B2" }}>
          Mes projets
        </Button>
      </Grid>
      <Grid container justifyContent="right" xs={6} md={3}>
        <Button onClick={handleNewProject} sx={{ color: "#0499B2" }}>
          démarrer le projet
        </Button>
      </Grid>
    </Grid>
  );
}

function TutorialVideosMapped({ tutorials }) {
  return (
    <Grid
      container
      gap={2}
      sx={{ mb: 4, p: 4 }}
      columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      {tutorials.map((video) => (
        <>
          <Grid item align="center" xs={12} md={6}>
            <div>
              <iframe
                width="100%"
                height="300px"
                src={video.link}
                frameborder="0"
                allow="autoplay; encrypted-media"
                allowFullScreen="true"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                title={video.name}
              />{" "}
            </div>
          </Grid>
          <Grid item align="left" xs={12} md={6}>
            <p>{video.name} </p>
          </Grid>
        </>
      ))}
    </Grid>
  );
}
