import * as React from "react";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import {
    Button,
    Grid,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
} from "@mui/material";
import Box from "@mui/material/Box";
import theme from "../../../Global/theme";
import { useNavigate, useParams } from "react-router-dom";
import {
    useGenerateVideoMutation,
    useGetFinalVideoProgressQuery,
} from "../videoWorkflowAPISlice";
import { getVideoProgress } from "../videoSlice";
import { useSelector } from "react-redux";

const drawerBleeding = 56;

const Root = styled("div")(({ theme }) => ({
    width: "100%",
    justifyContent: "center",
    marginTop: " 70px",
    bottom: 0,
    position: "fixed",
}));

const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "light" ? "#FBFEFF" : grey[800],
    boxShadow: "12px 12px 12px 12px grey",
}));

const Puller = styled(Box)(({ theme }) => ({
    width: 30,
    height: 6,
    marginBottom: 2,
    backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
    borderRadius: 3,
    position: "absolute",
    top: 8,
    left: "calc(50% - 15px)",
}));

export default function DrawerBottom({ noVideos }) {
    const navigate = useNavigate();
    const { project_id, name } = useParams();
    const [vidGeneratingModalOpen, setVidGeneratingModalOpen] =
        React.useState(false);
    const [generateVideo, { isLoading: generateVideoLoading }] =
        useGenerateVideoMutation();

    const handleGenerateVideo = () => {
        setVidGeneratingModalOpen(true);
        const request = {
            project_id: project_id,
        };
        generateVideo(request).unwrap((payload) => {});
    };

    const goToReorder = () => {
        navigate(`/project/${project_id}/video-reorder/${name}`);
    };

    const handleNewVideo = () => {
        navigate(`/project/${project_id}/video/${name}`);
    };

    return (
        <div>
            <Root>
                <CssBaseline />
                <Global
                    styles={{
                        ".MuiDrawer-root > .MuiPaper-root": {
                            overflow: "visible",
                        },
                    }}
                />

                <StyledBox
                    sx={{
                        px: 2,
                        pb: 2,
                        height: "100%",
                        marginTop: "10px",
                        overflow: "auto",
                    }}
                >
                    <ButtonsWithinDrawer
                        generateVideoLoading={generateVideoLoading}
                        handleNewVideo={handleNewVideo}
                        noVideos={noVideos}
                        goToReorder={goToReorder}
                        handleGenerateVideo={handleGenerateVideo}
                    />
                </StyledBox>
            </Root>
            <VideoGeneratingModal
                vidGeneratingModalOpen={vidGeneratingModalOpen}
                setVidGeneratingModalOpen={setVidGeneratingModalOpen}
            />
        </div>
    );
}

function ButtonsWithinDrawer({
    handleNewVideo,
    noVideos,
    goToReorder,
    handleGenerateVideo,
    generateVideoLoading,
}) {
    const { project_id } = useParams();
    const progress = useSelector(getVideoProgress);
    const { data: finalVideoState } = useGetFinalVideoProgressQuery(project_id);

    const isButtonDisabled = () => {
        return (
            noVideos ||
            generateVideoLoading ||
            progress > 60 ||
            finalVideoState?.status === "RECEIVED" ||
            finalVideoState?.status === "PENDING" ||
            finalVideoState?.status === "STARTED" ||
            (finalVideoState?.status !== "SUCCESS" &&
                finalVideoState?.status !== "FAILURE" &&
                finalVideoState?.status !== "NEVER")
        );
    };

    return (
        <Grid
            container
            sx={{ p: 4 }}
            gap={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            <Grid item align="center" xs={12}>
                <Button
                    variant="contained"
                    className="pink-button"
                    onClick={handleNewVideo}
                    disabled={progress > 60 ? true : false}
                >
                    AJOUTER UNE VIDEO
                </Button>
            </Grid>

            <Grid item align="center" xs={12}>
                <Button
                    disabled={noVideos}
                    variant="contained"
                    className="pink-button"
                    onClick={goToReorder}
                >
                    Changer l'ordre
                </Button>
            </Grid>

            <Grid item align="center" xs={12}>
                <Button
                    disabled={isButtonDisabled()}
                    variant="contained"
                    className="pink-button"
                    onClick={() => handleGenerateVideo()}
                >
                    Generer
                </Button>
            </Grid>
        </Grid>
    );
}

function VideoGeneratingModal({
    vidGeneratingModalOpen,
    setVidGeneratingModalOpen,
}) {
    const navigate = useNavigate();
    const handleClose = () => {
        setVidGeneratingModalOpen(false);
        navigate(`/`);
    };

    return (
        <Dialog
            // BackdropProps={{ invisible: true }}
            PaperProps={{
                style: theme.modalCardStyle.root,
            }}
            style={theme.modalStyle.root}
            open={vidGeneratingModalOpen}
            onClose={handleClose}
        >
            <DialogTitle>Génération de vidéo...</DialogTitle>
            <DialogContent>
                <p>
                    Cela peut prendre jusqu'à 10,00 minutes. Vous recevrez un
                    email lorsque votre la vidéo a été générée.
                </p>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>OK</Button>
            </DialogActions>
        </Dialog>
    );
}
